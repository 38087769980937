<template>
    <div class="section dark narrowLeft paddingBig photoBackground lightPhoto" id="background1">
        <div>
			<div class="banner">
				<div class="header">
					<div class="title">{{ $t("topFeatured.title") }}</div>
					<div v-html="$t('topFeatured.subTitle')" class="subTitle"></div>
				</div>
			</div>
            <ArticlesList :articles="articles" :typeOfBackground="typeOfBackground" :truncateTitle="true" />
		</div>
    </div>
</template>

<script>
import getArticles from '../composables/getArticles'
import GENERIC_VARIABLES from "../../config";
import LocalizedLink from "@/components/LocalizedLink.vue"
import ArticlesList from '@/components/ArticlesList.vue'


export default {
	name: 'topFeaturedArticles',
    components: { ArticlesList, LocalizedLink },
  	setup() {
		const typeOfBackground = "darkBackground"
		const top = "true"
		const novelties = "false"
		const limit = '4'
		const articleDetailsView = 'ArticleDetails'
		const subcategories = ['ELK12C000001', 'ELK12C0000020', 'ELK12C0000010']
        const filters = []

		const generateArticleUrl = (article) => {
			let url = "/"+article.url[GENERIC_VARIABLES.USER_LANG]['subcategorySlug'] ? article.url[GENERIC_VARIABLES.USER_LANG]['subcategorySlug'] : article.url['es']['subcategorySlug']+"/"+article.url[GENERIC_VARIABLES.USER_LANG]['articleSlug'] ? article.url[GENERIC_VARIABLES.USER_LANG]['articleSlug'] : article.url['es']['articleSlug']+"/"+article.id

			return url
		}


		const { articles, error, load } = getArticles(GENERIC_VARIABLES.PRODUCT_BRAND, subcategories, filters, novelties, top, limit)

		

		load()

		return { articles, error, GENERIC_VARIABLES, articleDetailsView, typeOfBackground, generateArticleUrl}
	}
}
</script>