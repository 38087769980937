import { ref } from "vue"
import GENERIC_VARIABLES from "../../config";
import Tr from "@/translations"


const searcher = (query) => {
    
    let API = process.env.VUE_APP_ROOT_API+"articles/searcher?term="+query+"&language="+Tr.currentLocale+"&images=false&thumbnails=false&similares=false&relacionados=false&description=false&features=false&video=false"

    const articles = ref([])
    const error = ref(null)

    const load = async () => {
        try {
            let response = await fetch(API)
            
            let data = await response.json()

            if(data.error) throw Error(data.message)
            if (data.code != "200") throw Error(data.message)
            
            articles.value = await data.results

        } catch (err) {
            error.value = err.message
        }

    }
    return { articles, error, load } 
}

export default searcher
