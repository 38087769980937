<template>
    <div id="content" class="sections">
        <Head>
        <!-- Primary Meta Tags -->
        <title>{{ changeMetatagHtml($t("cleaningTools.metaTitle")) }}</title>
        
        <link rel="canonical" href="https://komatools.com/es/herramientas-de-limpieza" />
        <link rel="alternate" href="https://komatools.com/es/herramientas-de-limpieza" hreflang="es" />
        <link rel="alternate" href="https://komatools.com/en/cleaning-tools" hreflang="en" />
        <link rel="alternate" href="https://komatools.com/fr/outils-de-nettoyage" hreflang="fr" />
        <link rel="alternate" href="https://komatools.com/es/herramientas-de-limpieza" hreflang="x-default" />

        <meta name="title" :content="changeMetatagHtml($t('cleaningTools.metaTitle'))">

        <meta name="description" :content="$t('cleaningTools.metaDescription')">

        <!-- Open Graph / Facebook -->
        <meta property="og:type" content="website">
        <meta property="og:url" content="https://komatools.com/">
        <meta property="og:title" :content="changeMetatagHtml($t('cleaningTools.metaTitle'))">
        <meta property="og:description" :content="$t('cleaningTools.metaDescription')">
        <meta property="og:image" content="https://komatools.com/img/komatoolsSocial.jpg">

        <!-- Twitter -->
        <meta property="twitter:card" content="summary_large_image">
        <meta property="twitter:url" content="https://komatools.com/">
        <meta property="twitter:title" :content="changeMetatagHtml($t('cleaningTools.metaTitle'))">
        <meta property="twitter:description" :content="$t('cleaningTools.metaDescription')">
        <meta property="twitter:image" content="https://komatools.com/img/komatoolsSocial.jpg">
        </Head>
        <div class="section light">
            <div>
                <a class="button" @click="$router.back()">
                    <div class="icon back"></div>
                    <div class="text">{{ $t("nav.back") }}</div>
                </a>
                <div class="banner centered">
                    <div class="header">            
                        <div class="title">{{ $t("cleaningTools.title") }}</div>
                        <div v-html="$t('cleaningTools.subTitle')" class="subTitle"></div>
                    </div>
                </div>
                <details>
                    <summary v-html="$t('cleaningTools.summary.title')"></summary>
                    <p v-html="$t('cleaningTools.summary.descriptionParagraph1')"></p>
                    <p v-html="$t('cleaningTools.summary.descriptionParagraph2')"></p>
                    <p v-html="$t('cleaningTools.summary.descriptionParagraph3')"></p>
                    <p v-html="$t('cleaningTools.summary.descriptionParagraph4')"></p>
                    <p v-html="$t('cleaningTools.summary.descriptionParagraph5')"></p>
                </details>
                <div class="banner centered" v-if="error">
                    <p v-html="$t(error)"></p>
                </div>
                <div v-else>
                    <ArticlesList :articles="articles" />
                </div>         
            </div>
        </div>
    </div>
</template>


<script>
import getArticles from '../../composables/getArticles'
import GENERIC_VARIABLES from "../../../config";
import ArticlesList from '@/components/ArticlesList.vue'
import { Head } from '@vueuse/head'



// Composition API and external JS
export default {
    name: "CleaningTools",
    components: { ArticlesList, Head },
    setup() {
       
        const subcategories = ['ELK12C0000020']


        const { articles, error, load } = getArticles(GENERIC_VARIABLES.PRODUCT_BRAND, subcategories)

        load()

        /**
        * This is a function to remove the special character | from javascript
        * More information: https://stackoverflow.com/questions/5796718/html-entity-decode
        */
        const changeMetatagHtml = (html) => {
          let txt = document.createElement("textarea");
          txt.innerHTML = html;
          return txt.value;

        }

        return { articles, error, GENERIC_VARIABLES, subcategories, changeMetatagHtml }
    }

}

/*const API = "http://localhost:8080/api/articles";

export default {
  name: "ArticlesList",
  data() {
      return {
        listItems: []
      }
    },
  methods: {
    async getItems() {
        try {
            const response = await fetch(API)
            const data = await response.json()
            // console.log(data)
            if (data.error) throw new Error("Articles not found")
            if (data.code != "200") throw new Error("Articles not found")
           
            this.listItems = data.results
            // console.log(this.listItems);
            // console.log(navigator.language)
        } catch (error) {
            console.log(error)
            
        }
        
    }
  },
  mounted(){
		this.getItems();
	}
};*/
</script>
