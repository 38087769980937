<template>
    <div></div>
</template>

<script>
import {} from './cookieconsent.min.js'

export default {
    name: 'CookieconsentComponent',
    mounted () {
        window.CookieConsent.init({
            "theme": {
                barColor: '#007ea4',
                barTextColor: '#FFF',
                barMainButtonColor: '#FFF',
                barMainButtonTextColor: '#2C7CBF',
                modalMainButtonColor: '#4285F4',
                modalMainButtonTextColor: '#FFF'
            },
            language: {
                // Current language
                current: 'es',
                locale: {
                    es: {
                        barMainText: 'Este sitio web utiliza cookies para proporcionar un mejor servicio.',
                        barLinkSetting: 'Configuración de cookies',
                        barBtnAcceptAll: 'Aceptar todas las cookies',
                        barBtnRejectAll: 'Rechazar todas las cookies',
                        modalMainTitle: 'Configuración de cookies',
                        modalMainText: 'Las cookies son pequeños datos enviados desde un sitio web y almacenados en el ordenador del usuario por el navegador web mientras el usuario esté navegando. Su navegador almacena cada mensaje en un pequeño archivo, llamado cookie. Cuando solicita otra página del servidor, su navegador envía la cookie de regreso al servidor. Las cookies fueron diseñadas para ser un mecanismo confiable para que los sitios web recuerden información o registren la actividad de navegación del usuario.',
                        modalBtnSave: 'Guardar configuración',
                        modalBtnAcceptAll: 'Aceptar todas las cookies',
                        modalAffectedSolutions: 'Cookies de este tipo:',
                        learnMore: 'Más información',
                        on: 'Sí',
                        off: 'No',
                    }
                }
            },
            // List all the categories you want to display
            categories: {
            // Unique name
            // This probably will be the default category
                required: {
                    // The cookies here are necessary and category cant be turned off.
                    // Wanted config value  will be ignored.
                    needed: true,
                    // The cookies in this category will be let trough.
                    // This probably should be false if not necessary category
                    wanted: true,
                    // If the checkbox is on or off at first run.
                    checked: true,
                    // Language settings for categories
                    language: {
                        locale: {
                            es: {
                                name: 'Cookies requeridas',
                                description: 'Estas cookies permiten al usuario la navegación a través de la página web y la utilización de las diferentes opciones o servicios que en ella existan como, por ejemplo, identificar la sesión, acceder a partes de acceso restringido, realizar el proceso de compra de un pedido, etc. El sitio web no puede funcionar sin estas cookies.',
                            }
                        }
                    }
                },
                analytics: {
                    needed: false,
                    wanted: false,
                    checked: false,
                    language: {
                        locale: {
                            es: {
                                name: "Cookies de Análisis o medición",
                                description: "Las cookies de análisis facilitan informaci\u00f3n estad\u00edstica totalmente an\u00f3nima sobre el uso que los usuarios realizan en la web."
                            }
                        }
                    }
                },
            },
            // List actual services here
            services: {
                // Unique name
                matomo: {
                    // Existing category Unique name
                    category: 'analytics',
                    // Type of blocking to apply here.
                    // This depends on the type of script we are trying to block
                    // Can be: dynamic-script, script-tag, wrapped, localcookie
                    type: 'dynamic-script',
                    // Only needed if "type: dynamic-script"
                    // The filter will look for this keyword in inserted scipt tags
                    // and block if match found
                    search: 'stats.elektro3.com',
                    // List of known cookie names or Regular expressions matching
                    // cookie names placed by this service.
                    // These willbe removed from current domain and .domain.
                    cookies: [
                        {
                            // Known cookie name.
                            name: '_pk_ref',
                            // Expected cookie domain.
                            domain: 'stats.elektro3.com',
                            language: {
                                locale: {
                                    es: {
                                        description: 'Permite el análisis estadístico de las visitas que recibe el web: las páginas que son visitadas, el tiempo que el usuario permanece en ellas, los enlaces en los que se pulsó y otra información similar.',
                                        expires: '6 meses',
                                    }
                                }
                            }
                        },
                        {
                            // Known cookie name.
                            name: '_pk_cvar',
                            // Expected cookie domain.
                            domain: 'stats.elektro3.com',
                            language: {
                                locale: {
                                    es: {
                                        description: 'Permite el análisis estadístico de las visitas que recibe el web: las páginas que son visitadas, el tiempo que el usuario permanece en ellas, los enlaces en los que se pulsó y otra información similar.',
                                        expires: '30 minutos',
                                    }
                                }
                            }
                        },
                        {
                            // Known cookie name.
                            name: '_pk_id',
                            // Expected cookie domain.
                            domain: 'stats.elektro3.com',
                            language: {
                                locale: {
                                    es: {
                                        description: 'Permite el análisis estadístico de las visitas que recibe el web: las páginas que son visitadas, el tiempo que el usuario permanece en ellas, los enlaces en los que se pulsó y otra información similar.',
                                        expires: '13 meses',
                                    }
                                }
                            }
                        },
                        {
                            // Known cookie name.
                            name: '_pk_ses',
                            // Expected cookie domain.
                            domain: 'stats.elektro3.com',
                            language: {
                                locale: {
                                    es: {
                                        description: 'Permite el análisis estadístico de las visitas que recibe el web: las páginas que son visitadas, el tiempo que el usuario permanece en ellas, los enlaces en los que se pulsó y otra información similar.',
                                        expires: '30 minutos',
                                    }
                                }
                            }
                        },
                        {
                            // Known cookie name.
                            name: 'matomo_sessid',
                            // Expected cookie domain.
                            domain: 'stats.elektro3.com',
                            language: {
                                locale: {
                                    es: {
                                        description: 'Previene ataques del tipo CSRF.'
                                    }
                                }
                            }
                        }
                    ],
                    language: {
                        locale: {
                            es: {
                                name: 'Matomo',
                                description: 'Las cookies de Matomo (anteriomente Piwik) nos permiten conocer los datos estadísticos sobre las visitas que recibe la web, siempre respetando la privacidad del usuario.',
                            }
                        }
                    }
                }
            }
        })
    }
}
</script>

<style>

</style>