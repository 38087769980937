<template>

    <div id="content" class="sections">

        <Head>
			<!-- Primary Meta Tags -->
			<title>{{ changeMetatagHtml($t("bestSellers.metaTitle")) }}</title>
			<meta name="title" :content="changeMetatagHtml($t('bestSellers.metaTitle'))">
			
			<meta name="description" :content="$t('bestSellers.metaDescription')">

			<!-- Open Graph / Facebook -->
			<meta property="og:type" content="website">
			<meta property="og:url" content="https://komatools.com/">
			<meta property="og:title" :content="changeMetatagHtml($t('bestSellers.metaTitle'))">
			<meta property="og:description" :content="$t('bestSellers.metaDescription')">
			<meta property="og:image" content="@/assets/img/komatoolsSocial.jpg">

			<!-- Twitter -->
			<meta property="twitter:card" content="summary_large_image">
			<meta property="twitter:url" content="https://komatools.com/">
			<meta property="twitter:title" :content="changeMetatagHtml($t('bestSellers.metaTitle'))">
			<meta property="twitter:description" :content="$t('bestSellers.metaDescription')">
			<meta property="twitter:image" content="@/assets/img/komatoolsSocial.jpg">
		</Head>

		<div class="section light"><div>

            <a class="button" @click="$router.back()">
                <div class="icon back"></div>
                <div class="text">{{ $t("nav.back") }}</div>
            </a>

			<div class="banner centered">
				<div class="header">
            
					<div class="title">{{ $t("bestSellers.title") }}</div>
					<div v-html="$t('bestSellers.subTitle')" class="subTitle"></div>
				</div>
			</div>

            <div v-if="error"> {{ error }}</div>
            <ArticlesList :articles="articles" />
            
			

		</div></div>

	</div>


</template>


<script>
import getArticles from '../../composables/getArticles'
import GENERIC_VARIABLES from "../../../config";
import ArticlesList from '@/components/ArticlesList.vue'
import { Head } from '@vueuse/head'


// Composition API and external JS
export default {
    name: "BestSellers",
    components: { ArticlesList, Head },
    setup() {
        const top = "false"
		const novelties = "true"
		const articleDetailsView = 'ArticleDetails'
		const filters = []
		const subcategories = ['ELK12C000001', 'ELK12C0000020', 'ELK12C0000010', 'ELK12C0000024']


        const { articles, error, load } = getArticles(GENERIC_VARIABLES.PRODUCT_BRAND, subcategories, filters, novelties, top)

        load()

        /**
		* This is a function to remove the special character | from javascript
		* More information: https://stackoverflow.com/questions/5796718/html-entity-decode
		 */
		const changeMetatagHtml = (html) => {
			let txt = document.createElement("textarea");
			txt.innerHTML = html;
			return txt.value;

		}

        return { articles, error, GENERIC_VARIABLES, subcategories, articleDetailsView, changeMetatagHtml }
    }

}
</script>
