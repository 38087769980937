<template>
    <div id="footer" class="sections">

		<div class="section container light paddingBig"><div>
			<div class="item">
				<img src="@/assets/img/komatools-catalogue-book.jpg" :alt="$t('catalogue.alt')">
			</div>
			<div class="item">
				<div class="texts">
					<div class="text">
						{{ $t("catalogue.download")}}
						<h2><u>{{ $t("catalogue.pdf")}}</u></h2>
					</div>
					<div class="buttons wrap centered">
						<a :href="generateCatalogueUrl()" class="button accent" download>
							<div class="icon download"></div>
							<div class="text">{{ $t("catalogue.here") }}</div>
						</a>
					</div>
				</div>
			</div>

		</div></div>	


		<div class="section paddingBigger logo light"><div>
			<div class="logo"></div>
			<div class="motto" v-html="$t('footer.logoTitle')"></div>
		</div></div>

		<div class="section paddingBigger linkGroups dark pro noPaddingBottom"><div>

			<div class="linkGroup grow">
				<div class="title">{{ $t("footer.whatAreYouLlokingFor") }}</div>
				<div class="links">
					<LocalizedLink class="option" :to="$t('routes.allArticles')"><div class="text">{{ $t("nav.allArticles") }}</div></LocalizedLink>
					<LocalizedLink class="option" :to="$t('routes.onForAllSystem')"><div class="text">{{ $t("nav.oneForAllTools") }}</div></LocalizedLink>
					<LocalizedLink class="option" :to="$t('routes.jardin')"><div class="text">{{ $t("nav.jardin") }}</div></LocalizedLink>
					<LocalizedLink class="option" :to="$t('routes.bricolage')"><div class="text">{{ $t("nav.bricolage") }}</div></LocalizedLink>
					<LocalizedLink class="option" :to="$t('routes.proTools')"><div class="text">{{ $t("nav.proTools") }}</div></LocalizedLink>
					<LocalizedLink class="option" :to="$t('routes.domesticTools')"><div class="text">{{ $t("nav.domesticTools") }}</div></LocalizedLink>
					<LocalizedLink class="option" :to="$t('routes.cleaningTools')"><div class="text">{{ $t("nav.cleaningTools") }}</div></LocalizedLink>
				</div>
				<div class="links">
					<LocalizedLink class="option" :to="$t('routes.batery')"><div class="text">{{ $t("nav.batery") }}</div></LocalizedLink>
					<LocalizedLink class="option" :to="$t('routes.cordedTools')"><div class="text">{{ $t("nav.cordedTools") }}</div></LocalizedLink>
					<LocalizedLink class="option" :to="$t('routes.gasoline')"><div class="text">{{ $t("nav.gasoline") }}</div></LocalizedLink>
					<LocalizedLink class="option" :to="$t('routes.measuringTools')"><div class="text">{{ $t("nav.measuringTools") }}</div></LocalizedLink>
					<LocalizedLink class="option" :to="$t('routes.cuttingTools')"><div class="text">{{ $t("nav.cuttingTools") }}</div></LocalizedLink>
					<LocalizedLink class="option" :to="$t('routes.drillBits')"><div class="text">{{ $t("nav.drillBits") }}</div></LocalizedLink>
				</div>
			</div>

			<div class="linkGroup grow">
				<div class="title">{{ $t("nav.products") }}</div>
				<div class="links">
					<LocalizedLink class="option" :to="$t('routes.bestSellers')"><div class="text">{{ $t("nav.bestSellers") }}</div></LocalizedLink>
					<LocalizedLink class="option" :to="$t('routes.novelties')"><div class="text">{{ $t("nav.novelties") }}</div></LocalizedLink>
				</div>
			</div>

			<div class="linkGroup grow">
				<div class="title">{{ $t("nav.others") }}</div>
				<div class="links">
					<LocalizedLink class="option" :to="$t('routes.bateriesAndCargers')"><div class="text">{{ $t("nav.bateriesAndCargers") }}</div></LocalizedLink>
					<LocalizedLink class="option" :to="$t('routes.accesorios')"><div class="text">{{ $t("nav.accesorios") }}</div></LocalizedLink>
					<LocalizedLink class="option" :to="$t('routes.dealers')"><div class="text">{{ $t("nav.dealers") }}</div></LocalizedLink>
					<LocalizedLink class="option" :to="$t('routes.spareParts')"><div class="text">{{ $t("nav.spareParts") }}</div></LocalizedLink>
					<LocalizedLink class="option" :to="$t('routes.consumables')"><div class="text">{{ $t("nav.consumables") }}</div></LocalizedLink>					
					<a :href="generateCatalogueUrl()" class="option" download><div class="text">{{ $t("nav.pdfCatalogue") }}</div></a>
				</div>
			</div>

		</div></div>
		<div class="section disclaimers dark pro paddingBottom"><div>
			<div>&copy; KomaTools {{ new Date().getFullYear() }}</div>
			<div>{{ $t('footer.allRightsReserved') }}</div>
			<LocalizedLink class="option" :to="$t('routes.protectionData')"><div class="text">{{ $t("nav.protectionData") }}</div></LocalizedLink>
		</div></div>

	</div>      
</template>

<script>
import LocalizedLink from "./LocalizedLink.vue"
import Tr from "@/translations"

export default {
  name: 'FooterComponent',
  components: { LocalizedLink },
  setup() {
		const generateCatalogueUrl = () => {

			let url = "/files/";

			if(Tr.currentLocale === 'pt') {
				url = url + "catalogo-koma-pt.pdf";
			} else {
				url = url + "catalogo-koma.pdf";
			}

			return url
		}

		return { generateCatalogueUrl }
	}
}

</script>