<template>
    <div id="content" class="sections">
        <Head>
			<!-- Primary Meta Tags -->
			<title>{{ changeMetatagHtml($t("allArticles.metaTitle")) }}</title>

            <link rel="canonical" href="https://komatools.com/es/catalogo-de-herramientas" />
            <link rel="alternate" href="https://komatools.com/es/catalogo-de-herramientas" hreflang="es" />
            <link rel="alternate" href="https://komatools.com/en/all-tools" hreflang="en" />
            <link rel="alternate" href="https://komatools.com/fr/catalogue-outils" hreflang="fr" />
            <link rel="alternate" href="https://komatools.com/es/catalogo-de-herramientas" hreflang="x-default" />

			<meta name="title" :content="changeMetatagHtml($t('allArticles.metaTitle'))">
			
			<meta name="description" :content="$t('allArticles.metaDescription')">

			<!-- Open Graph / Facebook -->
			<meta property="og:type" content="website">
			<meta property="og:url" content="https://komatools.com/">
			<meta property="og:title" :content="changeMetatagHtml($t('allArticles.metaTitle'))">
			<meta property="og:description" :content="$t('allArticles.metaDescription')">
			<meta property="og:image" content="https://komatools.com/img/komatoolsSocial.jpg">

			<!-- Twitter -->
			<meta property="twitter:card" content="summary_large_image">
			<meta property="twitter:url" content="https://komatools.com/">
			<meta property="twitter:title" :content="changeMetatagHtml($t('allArticles.metaTitle'))">
			<meta property="twitter:description" :content="$t('allArticles.metaDescription')">
			<meta property="twitter:image" content="https://komatools.com/img/komatoolsSocial.jpg">
		</Head>
        <div class="section light">
            <div>
                <a class="button" @click="$router.back()">
                    <div class="icon back"></div>
                    <div class="text">{{ $t("nav.back") }}</div>
                </a>
                <div class="banner centered">
                    <div class="header">
                        <div class="title">{{ $t("allArticles.title") }}</div>
                        <div v-html="$t('allArticles.subTitle')" class="subTitle"></div>
                    </div>
                </div>
                <details>
                    <summary v-html="$t('allArticles.summary.title')"></summary>
                    <p v-html="$t('allArticles.summary.descriptionParagraph1')"></p>
                    <p v-html="$t('allArticles.summary.descriptionParagraph2')"></p>
                    <p v-html="$t('allArticles.summary.descriptionParagraph3')"></p>
                    <p v-html="$t('allArticles.summary.descriptionParagraph4')"></p>
                    <p v-html="$t('allArticles.summary.descriptionParagraph5')"></p>
                    <p v-html="$t('allArticles.summary.descriptionParagraph6')"></p>
                    <p v-html="$t('allArticles.summary.descriptionParagraph7')"></p>
                    <p v-html="$t('allArticles.summary.descriptionParagraph8')"></p>
                    <p v-html="$t('allArticles.summary.descriptionParagraph9')"></p>
                    <p v-html="$t('allArticles.summary.descriptionParagraph10')"></p>
                </details>
                <div class="options buttons wrap centered">
                    <a class="button accent" v-for="(filter, index) in filtersRef" :key="index">
                        <span
                            @click="filterData(filter.code)"
                            :class="{ active: filter.code === activeFilter }"
                        >{{ $t(filter.text) }}</span>
                    </a>
                </div>

                <div class="banner centered" v-if="error">
                    <p v-html="$t(error)"></p>
                </div>
                <div v-else>
                    <ArticlesList :articles="searchedArticles" />
                </div>
            </div>
        </div>

    </div>
</template>


<script>
import getArticles from '../../composables/getArticles'
import GENERIC_VARIABLES from "../../../config";
import ArticlesList from '@/components/ArticlesList.vue'
import { Head } from '@vueuse/head'
import { computed, ref } from 'vue'


// Composition API and external JS
export default {
    name: "AllArticles",
    components: { ArticlesList, Head },
    setup() {

        const filtersRef = ref([      
			{ text: 'filtersArticles.all.text', code: ''},
			{ text: 'filtersArticles.bricolage.text', code: 'ELK12C000001'},
			{ text: 'filtersArticles.garden.text', code: 'ELK12C0000010'},
			{ text: 'filtersArticles.clean.text', code: 'ELK12C0000020'},
			{ text: 'filtersArticles.drill.text', code: 'ELK12C0000024'},
			{ text: 'filtersArticles.bateriesAndCargers.text', code: 'ELK12C0000030'},
			{ text: 'filtersArticles.consumables.text', code: 'ELK12C0000040'},
			{ text: 'filtersArticles.spareParts.text', code: 'ELK12C0000050'}
		])
    	const activeFilter = ref('');
        const { articles, error, load } = getArticles(GENERIC_VARIABLES.PRODUCT_BRAND)

        load()

        /**
		* This is a function to remove the special character | from javascript
		* More information: https://stackoverflow.com/questions/5796718/html-entity-decode
		 */
		const changeMetatagHtml = (html) => {
			let txt = document.createElement("textarea");
			txt.innerHTML = html;
			return txt.value;

		}

        const searchedArticles = computed(() => {
            if (activeFilter.value === '') {
                return articles.value;
            }
            // return articles.value.filter((article) => article.subcategoryName.es.toLowerCase() === activeFilter.value.toLowerCase());
            return articles.value.filter((article) => {
                
                return (
                    article.subcategoryCode  === activeFilter.value
                    
                );
            });
        });

		const filterData = (type) => {
			activeFilter.value = type;
		}

        return { searchedArticles, filtersRef, activeFilter, error, GENERIC_VARIABLES, changeMetatagHtml, filterData }
    }

}

/*const API = "http://localhost:8080/api/articles";

export default {
  name: "ArticlesList",
  data() {
      return {
        listItems: []
      }
    },
  methods: {
    async getItems() {
        try {
            const response = await fetch(API)
            const data = await response.json()
            // console.log(data)
            if (data.error) throw new Error("Articles not found")
            if (data.code != "200") throw new Error("Articles not found")
           
            this.listItems = data.results
            // console.log(this.listItems);
            // console.log(navigator.language)
        } catch (error) {
            console.log(error)
            
        }
        
    }
  },
  mounted(){
		this.getItems();
	}
};*/
</script>
