<template>
    <div>
        <section class="fdb-block">
            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-12 col-md-12">
                        <h1>Koma Tools, herramientas eléctricas para pros y manitas</h1>
                    </div>
                </div>
            </div>
            <div class="container-fluid mt-2 py-4 p-md-5">
                <div class="container">
                    <div class="row justify-content-center">
                        <div class="col-12 col-md-12">
                            <h2>Herramientas para bricolaje en el hogar</h2>
                        </div>
                    </div>
                    <div class="row justify-content-center align-items-center">
                        <div class="col-12 col-md-8">
                            <p>¿Eres un manitas con largo recorrido en el mundo del bricolaje doméstico o eres un iniciado en este mundillo? Seas del tipo que seas, en Koma Tools encontraras herramientas para lijar, atornillar, decapar, serrar y percutir.</p>
                            <p>Tener herramientas adecuadas es imprescindible para crear los espacios que te has imaginado. Las <b>herramientas Koma Tools</b> ofrecen resultados excelentes con una <b>relación calidad-precio insuperable para el bricolaje en casa</b>.</p>
                        </div>
                        <div class="col-12 col-md-4 text-center">
                            <img @click="showSingle(importFromAssetsPathKomaToolsBig)" loading="lazy" alt="herramientas para bricolaje en el hogar, bricolaje en casa, herramientas komatools" :src="importFromAssetsPathKomaTools" width="400" height="225" class="img-fluid">
                        </div>
                    </div>
                    <div class="row justify-content-center">
                        <div class="col-12 col-md-12">
                            <h3 class="h5">¡Conviértete en el manitas que siempre has deseado de la mano de Koma Tools!</h3>
                        </div>
                    </div>
                </div>
            </div>
            <div class="container-fluid mt-2 py-4 p-md-5 grey">
                <div class="container">
                    <div class="row justify-content-center">
                        <div class="col-12 col-md-12">
                            <h2>Herramientas para profesionales e instaladores</h2>
                        </div>
                    </div>
                    <div class="row justify-content-center align-items-center">
                        <div class="col-12 col-md-4 text-center order-2 order-md-1">
                            <img @click="showSingle(importFromAssetsPathKomaToolsProBig)" loading="lazy" alt="herramientas para profesionales e instaladores, herramientas koma tools, herramientas electricas" :src="importFromAssetsPathKomaToolsPro" width="400" height="225" class="img-fluid">
                        </div>
                        <div class="col-12 col-md-8 order-1 order-md-2">
                            <p>Tanto si eres un profesional como un amateur en la materia, la gama de herramientas Koma Tools Pro es la tuya. Herramientas para profesionales pensadas para hacerte el trabajo más fácil y seguro.</p>
                            <p>Con herramientas para serrar, lijar, equipos de limpieza, corta-césped para el jardín, escarificador / podador, re-cortadora / desbrozadoras, corta-setos, bombas de agua... ningún trabajo se te resistirá.</p>
                        </div>
                    </div>
                    <div class="row justify-content-center">
                        <div class="col-12 col-md-12">
                            <h3 class="h5">Consigue las mejores <b>herramientas Koma Tools Pro para profesionales</b> y obtén la satisfacción del trabajo bien hecho, rápido y al mejor precio.</h3>
                        </div>
                    </div>
                </div>
            </div>
            
            <div class="container-fluid mt-2 py-4 p-md-5">
                <div class="container">
                    <div class="row justify-content-center">
                        <div class="col-12 col-md-12">
                            <h2>Herramientas eléctricas Koma Tools</h2>
                        </div>
                    </div>
                    <div class="row justify-content-center align-items-center">
                        <div class="col-12 col-md-8">
                            <p>Con las herramientas eléctricas Koma Tools y Koma Tools Pro tareas que antaño llevaban gran cantidad de tiempo y dedicación, ahora se han vuelto sumamente fáciles.</p>
                            <p>Pero no siempre una herramienta eléctrica puede superar a una herramienta tradicional, por eso, es recomendable tener variedad a tu disposición.</p>
                            <p>Para un profesional realizar un trabajo de calidad en poco tiempo supone tener un cliente satisfecho que puede ofrecer más trabajo.</p>
                            <p>Para un "manitas" del bricolaje en casa, acabar el trabajo rápido, supone no tener la casa patas arriba y poder disfrutar de ese espacio agradable con el que ha soñado tantas veces.</p>
                            <p>Por todo eso, las <b>herramientas eléctricas Koma Tools y Koma tools Pro</b> son la mejor opción del mercado.</p>
                        </div>
                        <div class="col-12 col-md-4 text-center">
                            <img @click="showSingle(importFromAssetsPathKomaToolsElectricasBig)" loading="lazy" alt="herramientas electricas Koma Tools, aparatos electricos, komatools" :src="importFromAssetsPathKomaToolsElectricas" width="400" height="225" class="img-fluid">
                        </div>
                    </div>
                    <div class="row justify-content-center">
                        <div class="col-12 col-md-12">
                            <h3 class="h5">Koma Tools ofrece una amplia gama de aparatos eléctricos para realizar todos esos proyectos que siempre has querido realizar y hacerte la vida más fácil.</h3>
                        </div>
                    </div>
                </div>
            </div>
            <div class="container-fluid mt-2 py-4 p-md-5 grey">
                <div class="container">
                    <div class="row justify-content-center">
                        <div class="col-12 col-md-12">
                            <h2>Herramientas con sistema de baterías Koma Tools</h2>
                        </div>
                    </div>
                    <div class="row justify-content-center align-items-center">
                        <div class="col-12 col-md-4 text-center order-2 order-md-1">
                            <img @click="showSingle(importFromAssetsPathKomaToolsBateriasBig)" loading="lazy" alt="herramientas con sistema de baterias Koma Tools y Koma Tools Pro, herramientas sin cables, koma tools, koma tools pro" :src="importFromAssetsPathKomaToolsBaterias" width="400" height="225" class="img-fluid">
                        </div>
                        <div class="col-12 col-md-8 order-1 order-md-2">
                            <p>Las herramientas Koma Tools disponen de un <b>sistema de baterías intercambiables</b>.</p>
                            <p>Puedes conseguir una impresionante colección de herramientas eléctricas a un precio realmente bajo.</p>
                            <p>Sus baterías intercambiables se convierten en una gran alianza para la elección de Koma Tools como herramientas para su hogar y empresa.</p>
                            <p>Con el sistema de baterías Koma Tools podrás realizar todos los trabajos con libertad de movimientos y en cualquier espacio al no necesitar ningún enchufe.</p>
                        </div>
                    </div>
                    <div class="row justify-content-center">
                        <div class="col-12 col-md-12">
                            <h3 class="h5">Herramientas a batería Koma Tools para trabajar dónde quieras.</h3>
                        </div>
                    </div>
                </div>
            </div>
            <div class="container-fluid mt-2 py-4 p-md-5">
                <div class="container">    
                    <div class="row justify-content-center">
                        <div class="col-12 col-md-12">
                            <h2>Recambios para las herramientas Koma Tools</h2>
                            <p>Todas las herramientas Koma Tools disponen de piezas de recambio originales para que ningún imprevisto se interponga en tu trabajo.</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="container-fluid mt-2 py-4 p-md-5 grey">
                <div class="container">
                    <div class="row justify-content-center">
                        <div class="col-12 col-md-12">
                            <h2>Herramientas baratas</h2>
                            <p>Con Koma Tools y Koma Tools Pro puedes comprar herramientas de trabajo a precios inmejorables con las que obtener resultados eficientes, rápidos y de calidad.</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="container-fluid mt-2 py-4 p-md-5">
                <div class="container">
                    <div class="row justify-content-center">
                        <div class="col-12 col-md-12">
                            <h2>¿Interesado en las herramientas Koma Tools o Koma Tools Pro?</h2>
                            <p><a href="https://www.google.com/maps/d/embed?mid=1IpqNiVINJviqDfOslFCBOg7BivpRPrT0&ehbc=2E312F" target="_blank">Busca el distribuidor más cercano</a>, podrás ver y tocar las herramientas Koma Tools y Koma Tools Pro .</p>
                            <img src="@/assets/img/map.webp" alt="distribuidores utensilios komatools pro" @click="gotoGoogleMaps()" loading="lazy" class="img-fluid" width="1290" height="400">
                            <p class="mt-3">O, ponte en contacto con nosotros, ¡estaremos encantados de atenderte!</p>
                            <h3 class="text-center pt-3">
                                Tel: +34 977 79 29 45
                            </h3>
                            <p class="text-center no-margin no-padding"><small>Lunes a Viernes de 06:00h a 22:00h</small></p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section>
            <div class="container-fluid mt-2 py-4 p-md-5 grey">
                <div class="container">
                    <div class="row justify-content-center">
                        <div class="col-12 col-md-12">
                            <h2>¿Tienes dudas sobre las herramientas Koma Tools?</h2>
                            <p>Busca entre las preguntas frecuentes que nos realizan las personas interesadas en las herramientas Koma Tools antes de comprarlas.</p>
                        </div>
                    </div>
                    <div class="row justify-content-center">
                        <div class="col-12 col-md-12 text-left">
                            <FaqComponent/>
                        </div>
                    </div>
                </div>
            </div>
            <div class="container-fluid mt-2 py-4 p-md-5">
                <div class="container">
                    <div class="row justify-content-center">
                        <div class="col-12 col-md-12">
                            <div class="ctaFaq p-3">
                                <h3 class="h5">¿No encuentras lo que buscas?</h3>
                                <p class="mt-3">Ponte en contacto con nosotros, ¡estaremos encantados de atenderte!</p>
                                <h3 class="text-center pt-3">
                                    Tel: +34 977 79 29 45
                                </h3>
                                <p class="text-center no-margin no-padding"><small>Lunes a Viernes de 06:00h a 22:00h</small></p>
                            </div>
                        </div>
                    </div>
                </div>            
            </div>
        </section>
        <div>
            <!-- Initialize the lightbox -->
            <vue-easy-lightbox
            scrollDisabled
            escDisabled
            moveDisabled
            :visible="visible"
            :imgs="imgs"
            :index="index"
            @hide="handleHide"
            >
            </vue-easy-lightbox>
        </div>
    </div>
</template>

<script>
import { useHead } from '@vueuse/head'
import FaqComponent from '@/components/FaqComponent.vue'
import VueEasyLightbox from 'vue-easy-lightbox'

// https://webpack.js.org/guides/asset-management/#loading-images
// There is webpack's feature, which helps us handle the image and file path,
// and returns an image path relative to the publicPath.
import toolsKomaToolsPath from '@/assets/img/herramientas-koma-tools.png'
import toolsKomaToolsProPath from '@/assets/img/herramientas-koma-tools-profesionales.png'
import toolsKomaToolsProElectricasProPath from '@/assets/img/herramientas-koma-tools-electricas.png'
import toolsKomaToolsProBateriasProPath from '@/assets/img/herramientas-koma-tools-pro-baterias.png'

// The path for the hero lightbox image
import toolsKomaToolsBigPath from '@/assets/img/hero/herramientas-koma-tools.jpg'
import toolsKomaToolsProBigPath from '@/assets/img/hero/herramientas-koma-tools-profesionales.jpg'
import toolsKomaToolsElectricasBigPath from '@/assets/img/hero/herramientas-koma-tools-electricas.jpg'
import toolsKomaToolsBateriasBigPath from '@/assets/img/hero/herramientas-koma-tools-pro-baterias.jpg'

// The caption for the hero lightbox image
const toolsKomaToolsCaption = 'Herramientas para bricolaje en el hogar'
const toolsKomaToolsProCaption = 'Herramientas para profesionales e instaladores'
const toolsKomaToolsElectricasCaption = 'Herramientas eléctricas Koma Tools'
const toolsKomaToolsBateriasCaption = 'Herramientas con sistema de baterías Koma Tools y Koma Tools Pro'


export default {
    components: {
        FaqComponent,
        VueEasyLightbox
    },
    setup () {
        useHead({
            // Can be static or computed
            title: 'Herramientas para bricolaje doméstico y uso profesional | Koma Tools',
            meta: [
                {
                    name: "description",
                    content: 'Con Koma Tools y Koma Tools Pro puedes comprar herramientas de trabajo a precios inmejorables con las que obtener resultados eficientes, rápidos y de calidad. Llámanos y te asesoramos!',
                },
                {
                    name: "author",
                    content: 'komatools.com',
                },
                {
                    name: "keywords",
                    content: 'komatools, koma tools, herramientas electricas economicas, herramientas baterias, recambios para herramientas',
                },
                {
                    name: "copyright",
                    content: 'komatools.com',
                }
            ]
        })
    },
    data() {
        return {
            imgs: '', // Img Url , string or Array of string
            visible: false,
            index: 0, // default: 0        

            importFromAssetsPathKomaTools: toolsKomaToolsPath,
            importFromAssetsPathKomaToolsPro: toolsKomaToolsProPath,
            importFromAssetsPathKomaToolsElectricas: toolsKomaToolsProElectricasProPath,
            importFromAssetsPathKomaToolsBaterias: toolsKomaToolsProBateriasProPath,
            
            importFromAssetsPathKomaToolsBig: toolsKomaToolsBigPath,
            importFromAssetsPathKomaToolsProBig: toolsKomaToolsProBigPath,
            importFromAssetsPathKomaToolsElectricasBig: toolsKomaToolsElectricasBigPath,
            importFromAssetsPathKomaToolsBateriasBig: toolsKomaToolsBateriasBigPath,

            // The array of lightbox images
            arrayImgs: [
                { title: toolsKomaToolsCaption, src: toolsKomaToolsBigPath }, 
                { title: toolsKomaToolsProCaption, src: toolsKomaToolsProBigPath },
                { title: toolsKomaToolsElectricasCaption, src: toolsKomaToolsElectricasBigPath },
                { title: toolsKomaToolsBateriasCaption, src: toolsKomaToolsBateriasBigPath }]

        }
    },
    methods: {
        showSingle(img) {
            // Search in the array of images the one that has been clicked
            this.arrayImgs.forEach((item, index) => {
                if (item.src == img) {
                    // Put the pointer of the array the position occupied by the image, and pass the index
                    this.index = index
                }
            })
            // Pass all the images
            this.imgs = this.arrayImgs;

            // Show it
            this.show()

        },
        show() {
            this.visible = true
        },
        // Hide the gallery
        handleHide() {
            this.visible = false
        },
        gotoGoogleMaps() {
            window.open("https://www.google.com/maps/d/embed?mid=1IpqNiVINJviqDfOslFCBOg7BivpRPrT0&ehbc=2E312F")
        }
        
    }
}
</script>
