<template>
    <div id="content" class="sections">
        <Head>
            <!-- Primary Meta Tags -->
            <title>{{ changeMetatagHtml($t("bateriesAndCargers.metaTitle")) }}</title>
            
            <link rel="canonical" href="https://komatools.com/es/baterias-y-cargadores" />
            <link rel="alternate" href="https://komatools.com/es/baterias-y-cargadores" hreflang="es" />
            <link rel="alternate" href="https://komatools.com/en/batteries-and-chargers" hreflang="en" />
            <link rel="alternate" href="https://komatools.com/fr/batteries-et-chargeurs" hreflang="fr" />
            <link rel="alternate" href="https://komatools.com/es/baterias-y-cargadores" hreflang="x-default" />

            <meta name="title" :content="changeMetatagHtml($t('bateriesAndCargers.metaTitle'))">
            
            <meta name="description" :content="$t('bateriesAndCargers.metaDescription')">

            <!-- Open Graph / Facebook -->
            <meta property="og:type" content="website">
            <meta property="og:url" content="https://komatools.com/">
            <meta property="og:title" :content="changeMetatagHtml($t('bateriesAndCargers.metaTitle'))">
            <meta property="og:description" :content="$t('bateriesAndCargers.metaDescription')">
            <meta property="og:image" content="https://komatools.com/img/komatoolsSocial.jpg">

            <!-- Twitter -->
            <meta property="twitter:card" content="summary_large_image">
            <meta property="twitter:url" content="https://komatools.com/">
            <meta property="twitter:title" :content="changeMetatagHtml($t('bateriesAndCargers.metaTitle'))">
            <meta property="twitter:description" :content="$t('bateriesAndCargers.metaDescription')">
            <meta property="twitter:image" content="https://komatools.com/img/komatoolsSocial.jpg">
        </Head>
        <div class="section light">
            <div>
                <a class="button" @click="$router.back()">
                    <div class="icon back"></div>
                    <div class="text">{{ $t("nav.back") }}</div>
                </a>
                <div class="banner">
                    <div class="header">
                        <h1 class="title">{{ $t("bateriesAndCargers.title") }}</h1>
                        <h2 v-html="$t('bateriesAndCargers.subTitle')" class="subTitle"></h2>
                    </div>
                </div>
                <details>
                    <summary v-html="$t('bateriesAndCargers.summary.title')"></summary>
                    <p v-html="$t('bateriesAndCargers.summary.descriptionParagraph1')"></p>
                    <p v-html="$t('bateriesAndCargers.summary.descriptionParagraph2')"></p>
                    <p v-html="$t('bateriesAndCargers.summary.descriptionParagraph3')"></p>
                    <p v-html="$t('bateriesAndCargers.summary.descriptionParagraph4')"></p>
                    <p v-html="$t('bateriesAndCargers.summary.descriptionParagraph5')"></p>
                    <p v-html="$t('bateriesAndCargers.summary.descriptionParagraph6')"></p>
                </details>
                <div class="banner centered" v-if="error">
                    <p v-html="$t(error)"></p>
                </div>
                <div v-else>
                    <ArticlesList :articles="articles" />
                </div>
            </div>
        </div>
    </div>
</template>


<script>
import getArticles from '../../composables/getArticles'
import GENERIC_VARIABLES from "../../../config";
import ArticlesList from '@/components/ArticlesList.vue'
import { Head } from '@vueuse/head'



// Composition API and external JS
export default {
    name: "BateriesAndCargers",
    components: { ArticlesList, Head },
    setup() {
        const subcategories = ['ELK12C0000030']
        const filters = []

        const { articles, error, load } = getArticles(GENERIC_VARIABLES.PRODUCT_BRAND, subcategories, filters)

        load()

        /**
		* This is a function to remove the special character | from javascript
		* More information: https://stackoverflow.com/questions/5796718/html-entity-decode
		 */
		const changeMetatagHtml = (html) => {
			let txt = document.createElement("textarea");
			txt.innerHTML = html;
			return txt.value;

		}

        return { articles, error, GENERIC_VARIABLES, subcategories, changeMetatagHtml }
    }

}
</script>

<style>
/* details {
    padding: 0;
    font-size: .8rem;
    color: rgb(156, 156, 156);
} */
</style>
