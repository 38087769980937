<template>
    <div v-if="articles.length">
        <div class="productCards">
            <router-link 
                :to="{ name: 'ArticleDetails', params: { subcategory: item.url[Tr.currentLocale] ? item.url[Tr.currentLocale]['subcategorySlug'] : item.url['es']['subcategorySlug'], slug: item.url[Tr.currentLocale] ? item.url[Tr.currentLocale]['articleSlug'] : item.url['es']['articleSlug'], id: item.id } }" 
                :class="productCardBackground" 
                v-for="(item, index) in articles" 
                :key="item.id"
            >

                <img :src="apiUrl+item.thumbnail" :alt="item.name[Tr.currentLocale] ? item.name[Tr.currentLocale]+' | '+ extraKeyword : item.name['es']+' | '+ extraKeyword" :title="item.name[Tr.currentLocale] ? item.name[Tr.currentLocale]+' | '+ extraKeyword : item.name['es']+' | '+ extraKeyword" loading="lazy" v-if="index === 5">

                <img :src="apiUrl+item.thumbnail" :alt="item.name[Tr.currentLocale] ? item.name[Tr.currentLocale] : item.name['es']" :title="item.name[Tr.currentLocale] ? item.name[Tr.currentLocale] : item.name['es']" loading="lazy" v-else>
                <div class="texts">
                    <div class="title" v-if="truncateTitle">
                        <span v-if="item.name[Tr.currentLocale]">
                          {{item.name[Tr.currentLocale] ? truncate(item.name[Tr.currentLocale], 35, '...') : truncate(item.name["es"], 35, '...') }}
                        </span>
                    </div>
                    <div class="title" v-else>
                        {{item.name[Tr.currentLocale] ? item.name[Tr.currentLocale] : item.name["es"]}}
                    </div>
                </div>
                <!-- <div class="badges" v-if="item.isKomaToolsPro">
                    <img src="@/assets/img/komatools-pro-badge.svg">
                </div> -->
            </router-link>
        </div>

    </div>
    <div v-else style="display: flex; justify-content: center;">
        <div class="half-circle-spinner">
            <div class="circle circle-1"></div>
            <div class="circle circle-2"></div>
        </div>
    </div>
</template>

<script>
import GENERIC_VARIABLES from "../../config";
import Tr from "@/translations"
import ButtonsFilter from './ButtonsFilter.vue'



export default {
    props: ['articles', 'keyword', 'filtersRef', 'typeOfBackground', 'truncateTitle'],
    components: { ButtonsFilter },
    setup(props) {
        const apiUrl = process.env.VUE_APP_API_URL_IMAGES
        const productCardBackground = (props.typeOfBackground ? props.typeOfBackground : 'lightBackground') + " productCard"

        const extraKeyword = props.keyword ? props.keyword : ''

        const truncate = (text, length, suffix) => {
            if (text.length > length) {
              return text.substring(0, length) + suffix
            } else {
              return text
            }
        }

        return { apiUrl, GENERIC_VARIABLES, extraKeyword, productCardBackground, Tr, truncate}
    }
    
}
</script>

<style>
.half-circle-spinner, .half-circle-spinner * {
    box-sizing: border-box;
  }

  .half-circle-spinner {
    width: 60px;
    height: 60px;
    border-radius: 100%;
    position: relative;
  }

  .half-circle-spinner .circle {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 100%;
    border: calc(60px / 10) solid transparent;
  }

  .half-circle-spinner .circle.circle-1 {
    border-top-color: hsl(var(--proColorHue), var(--proColorSaturation), var(--proColorLightness));
    animation: half-circle-spinner-animation 1s infinite;
  }

  .half-circle-spinner .circle.circle-2 {
    border-bottom-color: 	hsl(var(--proColorHue), var(--proColorSaturation), calc(var(--proColorLightness) + 5%));
    animation: half-circle-spinner-animation 1s infinite alternate;
  }

  @keyframes half-circle-spinner-animation {
    0% {
      transform: rotate(0deg);

    }
    100%{
      transform: rotate(360deg);
    }
  }
</style>
