<template>

	<div id="content" class="sections">

		<Head>
			<!-- Primary Meta Tags -->
			<title>{{ changeMetatagHtml($t("proTools.metaTitle")) }}</title>

			<link rel="canonical" href="https://komatools.com/es/herramientas-para-profesionales" />
            <link rel="alternate" href="https://komatools.com/es/herramientas-para-profesionales" hreflang="es" />
            <link rel="alternate" href="https://komatools.com/en/profesional-tools" hreflang="en" />
            <link rel="alternate" href="https://komatools.com/fr/outillage-pour-professionnels" hreflang="fr" />
            <link rel="alternate" href="https://komatools.com/es/herramientas-para-profesionales" hreflang="x-default" />

			<meta name="title" :content="changeMetatagHtml($t('proTools.metaTitle'))">
			
			<meta name="description" :content="$t('proTools.metaDescription')">

			<!-- Open Graph / Facebook -->
			<meta property="og:type" content="website">
			<meta property="og:url" content="https://komatools.com/">
			<meta property="og:title" :content="changeMetatagHtml($t('proTools.metaTitle'))">
			<meta property="og:description" :content="$t('proTools.metaDescription')">
			<meta property="og:image" content="https://komatools.com/img/komatoolsSocial.jpg">

			<!-- Twitter -->
			<meta property="twitter:card" content="summary_large_image">
			<meta property="twitter:url" content="https://komatools.com/">
			<meta property="twitter:title" :content="changeMetatagHtml($t('proTools.metaTitle'))">
			<meta property="twitter:description" :content="$t('proTools.metaDescription')">
			<meta property="twitter:image" content="https://komatools.com/img/komatoolsSocial.jpg">
		</Head>

		<div class="section light">
			<div>
				<a class="button" @click="$router.back()">
					<div class="icon back"></div>
					<div class="text">{{ $t("nav.back") }}</div>
				</a>		

				<div class="banner centered">
					<div class="header">
						<h1 class="title">{{ $t("proTools.title") }}</h1>
						<h2 v-html="$t('proTools.subTitle')" class="subTitle"></h2>
					</div>
				</div>
				<details>
                    <summary v-html="$t('proTools.summary.title')"></summary>
                    <p v-html="$t('proTools.summary.descriptionParagraph1')"></p>
                    <p v-html="$t('proTools.summary.descriptionParagraph2')"></p>
                    <p v-html="$t('proTools.summary.descriptionParagraph3')"></p>
                    <p v-html="$t('proTools.summary.descriptionParagraph4')"></p>
                    <p v-html="$t('proTools.summary.descriptionParagraph5')"></p>
                    <p v-html="$t('proTools.summary.descriptionParagraph6')"></p>
                </details>

				<div class="options buttons wrap centered">
					<!-- <input type="text" placeholder="Search..." v-model="searchQuery" /> -->
					<a class="button accent" v-for="(filter, index) in filtersRef" :key="index">
						<span
							@click="filterData(filter.code)"
							:class="{ active: filter.code === activeFilter }"
						>{{ $t(filter.text) }}</span>
					</a>
				</div>

				<div class="banner centered" v-if="error">
					<p v-html="$t(error)"></p>
				</div>
				<div v-else>
					<ArticlesList :articles="searchedArticles" />
				</div>			

			</div>
		</div>

	</div>


</template>


<script>
import getArticles from '../../composables/getArticles'
import GENERIC_VARIABLES from "../../../config";
import ArticlesList from '@/components/ArticlesList.vue'
import { Head } from '@vueuse/head'
import { computed, ref } from 'vue'


// Composition API and external JS
export default {
    name: "ProTools",
    components: { ArticlesList, Head },
    setup() {
       	const searchQuery = ref("");

		// const filtersRef = ref(['Todos', 'Jardin', 'Bricolaje', 'Recambios', 'Baterias y cargadores']);
		const filtersRef = ref([      
			{ text: 'filtersArticles.all.text', code: ''},
			{ text: 'filtersArticles.bricolage.text', code: 'ELK12C000001'},
			{ text: 'filtersArticles.garden.text', code: 'ELK12C0000010'},
			{ text: 'filtersArticles.clean.text', code: 'ELK12C0000020'},
			{ text: 'filtersArticles.bateriesAndCargers.text', code: 'ELK12C0000030'},
			{ text: 'filtersArticles.consumables.text', code: 'ELK12C0000040'},
			{ text: 'filtersArticles.spareParts.text', code: 'ELK12C0000050'}
		])
    	// const activeFilter = ref({ text: 'filtersArticles.all.text' });
		
    	// const activeFilter = ref('Todos');
    	const activeFilter = ref('');

        
		const subcategories = []
        const filters = ["gama:pro series"]
        const { articles, error, load } = getArticles(GENERIC_VARIABLES.PRODUCT_BRAND, subcategories, filters)

        load()

        /**
        * This is a function to remove the special character | from javascript
        * More information: https://stackoverflow.com/questions/5796718/html-entity-decode
        */
        const changeMetatagHtml = (html) => {
          let txt = document.createElement("textarea");
          txt.innerHTML = html;
          return txt.value;

        }

        const searchedArticles = computed(() => {
			if(searchQuery.value) {
				return articles.value.filter((article) => {
					return (
						article.name.es
						.toLowerCase()
						.indexOf(searchQuery.value.toLowerCase()) != -1
					);
				});
			} else {
				if (activeFilter.value === '') {
					return articles.value;
				}
				// return articles.value.filter((article) => article.subcategoryName.es.toLowerCase() === activeFilter.value.toLowerCase());
				return articles.value.filter((article) => {
					
					return (
						article.subcategoryCode  === activeFilter.value
						
					);
				});
			}
			
        });

		const filterData = (type) => {
			activeFilter.value = type;
		}

        return { searchedArticles, searchQuery, filtersRef, activeFilter, error, GENERIC_VARIABLES, subcategories, changeMetatagHtml, filterData }
    }

}
</script>
