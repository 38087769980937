<template>

    <div id="content" class="sections">
        <Head>
            <!-- Primary Meta Tags -->
            <title>{{ changeMetatagHtml($t("measuringTools.metaTitle")) }}</title>

            <link rel="canonical" href="https://komatools.com/es/herramientas-de-medicion" />
            <link rel="alternate" href="https://komatools.com/es/herramientas-de-medicion" hreflang="es" />
            <link rel="alternate" href="https://komatools.com/en/power-tools" hreflang="en" />
            <link rel="alternate" href="https://komatools.com/fr/outillage-electriques" hreflang="fr" />
            <link rel="alternate" href="https://komatools.com/es/herramientas-de-medicion" hreflang="x-default" />

            <meta name="title" :content="changeMetatagHtml($t('measuringTools.metaTitle'))">
            <meta name="description" :content="changeMetatagHtml($t('measuringTools.metaDescription'))">

            <!-- Open Graph / Facebook -->
            <meta property="og:type" content="website">
            <meta property="og:url" content="https://komatools.com/">
            <meta property="og:title" :content="changeMetatagHtml($t('measuringTools.metaTitle'))">
            <meta property="og:description" :content="changeMetatagHtml($t('measuringTools.metaDescription'))">
            <meta property="og:image" content="https://komatools.com/img/komatoolsSocial.jpg">

            <!-- Twitter -->
            <meta property="twitter:card" content="summary_large_image">
            <meta property="twitter:url" content="https://komatools.com/">
            <meta property="twitter:title" :content="changeMetatagHtml($t('measuringTools.metaTitle'))">
            <meta property="twitter:description" :content="changeMetatagHtml($t('measuringTools.metaDescription'))">
            <meta property="twitter:image" content="https://komatools.com/img/komatoolsSocial.jpg">
        </Head>

        <div class="section light"><div>

            <a class="button" @click="$router.back()">
                <div class="icon back"></div>
                <div class="text">{{ $t("nav.back") }}</div>
            </a>

            <div class="banner centered">
                <div class="header">            
                    <h1 class="title">{{ $t("measuringTools.title") }}</h1>
                    <h2 v-html="$t('measuringTools.subTitle')" class="subTitle"></h2>
                </div>
            </div>

            <details style="padding: 0;">
                <summary v-html="$t('measuringTools.summary.title')"></summary>
                <p v-html="$t('measuringTools.summary.descriptionParagraph1')"></p>
                <p v-html="$t('measuringTools.summary.descriptionParagraph2')"></p>
                <p v-html="$t('measuringTools.summary.descriptionParagraph3')"></p>
            </details>

            <div class="options buttons wrap centered">
                <!-- <input type="text" placeholder="Search..." v-model="searchQuery" /> -->
                <a class="button accent" v-for="(filter, index) in filtersRef" :key="index">
                    <span
                        @click="filterData(filter.code)"
                        :class="{ active: filter.code === activeFilter }"
                    >{{ $t(filter.text) }}</span>
                </a>
            </div>

            <div class="banner centered" v-if="error">
                <p v-html="$t(error)"></p>
            </div>
            <div v-else>
                <ArticlesList :articles="searchedArticles" />
            </div>
        </div></div>
    </div>
</template>


<script>
import getArticles from '../../composables/getArticles'
import GENERIC_VARIABLES from "../../../config";
import ArticlesList from '@/components/ArticlesList.vue'
import { Head } from '@vueuse/head'
import { computed, ref } from 'vue'

// Composition API and external JS
export default {
    name: "MeasuringTools",
    components: { ArticlesList, Head },
    setup() {

        const filtersRef = ref([      
			{ text: 'filtersArticles.all.text', code: ''},
			{ text: 'filtersArticles.compact.text', code: 'compact'},
			{ text: 'filtersArticles.standard.text', code: 'standard'},
			{ text: 'filtersArticles.magnet.text', code: 'magnet'},
		])
    	const activeFilter = ref('');
       
        const subcategories = []
        const features = true
        const filters = ["herramienta de medición:si"]

        //brand = null, subcategories = null, filters = null, novelties = null, top = null, limit = null, query = null, features = false
        const { articles, error, load } = getArticles(GENERIC_VARIABLES.PRODUCT_BRAND, subcategories, filters, null, null, null, null, features)

        load()

        /**
        * This is a function to remove the special character | from javascript
        * More information: https://stackoverflow.com/questions/5796718/html-entity-decode
        */
        const changeMetatagHtml = (html) => {
          let txt = document.createElement("textarea");
          txt.innerHTML = html;
          return txt.value;

        }

        const searchedArticles = computed(() => {
            let tempArticles = []
            if (activeFilter.value === '') {
                tempArticles = articles.value;
            }


            articles.value.forEach(article => {
                    article.features.forEach((feature) => {
                        if(feature["es"].typeName === "Modelo") {
                            if(feature["es"].value.toLowerCase() === activeFilter.value) {
                                tempArticles.push(article)
                            }
                        }
                    })

            })

            return tempArticles

        });

        const filterData = (type) => {
			activeFilter.value = type;
		}

        return { searchedArticles, filtersRef, activeFilter, error, GENERIC_VARIABLES, changeMetatagHtml, filterData }

    }

}
</script>
