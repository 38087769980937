<template>

<div id="content" class="sections">

    <Head>
    <!-- Primary Meta Tags -->
    <title>{{ changeMetatagHtml($t("cuttingTools.metaTitle")) }}</title>

    <link rel="canonical" href="https://komatools.com/es/herramientas-de-corte" />
    <link rel="alternate" href="https://komatools.com/es/herramientas-de-corte" hreflang="es" />
    <link rel="alternate" href="https://komatools.com/en/corded-power-tools" hreflang="en" />
    <link rel="alternate" href="https://komatools.com/fr/outils-a-cordon" hreflang="fr" />
    <link rel="alternate" href="https://komatools.com/es/herramientas-de-corte" hreflang="x-default" />

    <meta name="title" :content="changeMetatagHtml($t('cuttingTools.metaTitle'))">

    <meta name="description" :content="$t('cuttingTools.metaDescription')">

    <!-- Open Graph / Facebook -->
    <meta property="og:type" content="website">
    <meta property="og:url" content="https://komatools.com/">
    <meta property="og:title" :content="changeMetatagHtml($t('cuttingTools.metaTitle'))">
    <meta property="og:description" :content="$t('cuttingTools.metaDescription')">
    <meta property="og:image" content="https://komatools.com/img/komatoolsSocial.jpg">

    <!-- Twitter -->
    <meta property="twitter:card" content="summary_large_image">
    <meta property="twitter:url" content="https://komatools.com/">
    <meta property="twitter:title" :content="changeMetatagHtml($t('cuttingTools.metaTitle'))">
    <meta property="twitter:description" :content="$t('cuttingTools.metaDescription')">
    <meta property="twitter:image" content="https://komatools.com/img/komatoolsSocial.jpg">
    </Head>
	<div class="section light">
        <div>
            <a class="button" @click="$router.back()">
                <div class="icon back"></div>
                <div class="text">{{ $t("nav.back") }}</div>
            </a>
			<div class="banner centered">
				<div class="header">            
					<div class="title">{{ $t("cuttingTools.title") }}</div>
					<div v-html="$t('cuttingTools.subTitle')" class="subTitle"></div>
				</div>
			</div>
            <details>
                <summary v-html="$t('cuttingTools.summary.title')"></summary>
                <p v-html="$t('cuttingTools.summary.descriptionParagraph1')"></p>
                <p v-html="$t('cuttingTools.summary.descriptionParagraph2')"></p>
                <p v-html="$t('cuttingTools.summary.descriptionParagraph3')"></p>
                <p v-html="$t('cuttingTools.summary.descriptionParagraph4')"></p>
                <p v-html="$t('cuttingTools.summary.descriptionParagraph5')"></p>
            </details>
            <div class="options buttons wrap centered">
                
            </div>
            <div class="banner centered" v-if="error">
                <p v-html="$t(error)"></p>
            </div>
            <div v-else>
                <ArticlesList :articles="articles" />
            </div>
		</div>
    </div>
</div>
</template>


<script>
import getArticles from '../../composables/getArticles'
import GENERIC_VARIABLES from "../../../config";
import ArticlesList from '@/components/ArticlesList.vue'
import { Head } from '@vueuse/head'

// Composition API and external JS
export default {
    name: "CuttingTools",
    components: { ArticlesList, Head },
    setup() {

       
        const subcategories = []
        const filters = ["Herramienta de corte:si"]
        
        const { articles, error, load } = getArticles(GENERIC_VARIABLES.PRODUCT_BRAND, subcategories, filters)

        load()

        /**
        * This is a function to remove the special character | from javascript
        * More information: https://stackoverflow.com/questions/5796718/html-entity-decode
        */
        const changeMetatagHtml = (html) => {
          let txt = document.createElement("textarea");
          txt.innerHTML = html;
          return txt.value;

        }

        return { articles, error, GENERIC_VARIABLES, changeMetatagHtml }

    }

}
</script>
