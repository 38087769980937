<template>
    <div id="content" class="sections">
        <Head>
			<!-- Primary Meta Tags -->
			<title>{{ changeMetatagHtml($t("allArticles.metaTitle")) }}</title>
			<meta name="title" :content="changeMetatagHtml($t('allArticles.metaTitle'))">
			
			<meta name="description" :content="$t('allArticles.metaDescription')">

			<!-- Open Graph / Facebook -->
			<meta property="og:type" content="website">
			<meta property="og:url" content="https://komatools.com/">
			<meta property="og:title" :content="changeMetatagHtml($t('allArticles.metaTitle'))">
			<meta property="og:description" :content="$t('allArticles.metaDescription')">
			<meta property="og:image" content="@/assets/img/komatoolsSocial.jpg">

			<!-- Twitter -->
			<meta property="twitter:card" content="summary_large_image">
			<meta property="twitter:url" content="https://komatools.com/">
			<meta property="twitter:title" :content="changeMetatagHtml($t('allArticles.metaTitle'))">
			<meta property="twitter:description" :content="$t('allArticles.metaDescription')">
			<meta property="twitter:image" content="@/assets/img/komatoolsSocial.jpg">
		</Head>
        <div class="section light">
            <div>
                <a class="button" @click="$router.back()">
                    <div class="icon back"></div>
                    <div class="text">{{ $t("nav.back") }}</div>
                </a>
                <div class="banner centered">
                    <div class="header">
                        <div class="title">{{ $t("searcher.title") }}</div>
                        <div v-html="$t('searcher.subTitle')" class="subTitle"></div>
                    </div>
                </div>
                <div class="banner centered" v-if="error">
                    <p v-html="$t(error, {term: term})"></p>
                </div>
                <div v-else>
                    <ArticlesList :articles="articles" />
                </div>
            </div>
        </div>

    </div>
</template>


<script>
import searcher from '../../composables/searcher'
import GENERIC_VARIABLES from "../../../config";
import ArticlesList from '@/components/ArticlesList.vue'
import { Head } from '@vueuse/head'



// Composition API and external JS
export default {
    props: ['query'],
    name: "Searcher",
    components: { ArticlesList, Head },
    setup(props) {

        const { articles, error, load } = searcher(String(props.query))

        load()

        /**
		* This is a function to remove the special character | from javascript
		* More information: https://stackoverflow.com/questions/5796718/html-entity-decode
		 */
		const changeMetatagHtml = (html) => {
			let txt = document.createElement("textarea");
			txt.innerHTML = html;
			return txt.value;

		}

        const term = String(props.query)

        return { articles, error, GENERIC_VARIABLES, term, changeMetatagHtml }
    }

}
</script>
