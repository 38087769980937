<template>
    <div id="topBar" class="">

		<div class="header"><div>

			<div class="smallScreenMenu">
				<label for="smallScreenMenuToggle">
					<div class="icon menu"></div>
				</label>
				<input type="checkbox" id="smallScreenMenuToggle">
				<div class="menuContainer buttons vertical">

				<LocalizedLink class="button" :to="$t('routes.bateriesSystem')" @click="closeNavBar">
					<div class="icon go"></div>
					<div class="text">{{ $t("nav.bateriesSystem") }}</div>
				</LocalizedLink>
				<LocalizedLink class="button" :to="$t('routes.power')" @click="closeNavBar">
					<div class="icon go"></div>
					<div class="text">{{ $t("nav.power") }}</div>
				</LocalizedLink>
				<LocalizedLink class="button" :to="$t('routes.jardin')" @click="closeNavBar">
					<div class="icon go"></div>
					<div class="text">{{ $t("nav.jardin") }}</div>
				</LocalizedLink>
				<LocalizedLink class="button" :to="$t('routes.accesorios')" @click="closeNavBar">
					<div class="icon go"></div>
					<div class="text">{{ $t("nav.accesorios") }}</div>
				</LocalizedLink>
				<LocalizedLink class="button" :to="$t('routes.dealers')" @click="closeNavBar">
					<div class="icon go"></div>
					<div class="text">{{ $t("nav.dealers") }}</div>
				</LocalizedLink>
				
				<LocaleSwitcherMobile />

				</div>
			</div>

			<LocalizedLink class="logo" to="/"></LocalizedLink>


			<div class="buttons packed right">

				<div class="button outlined overDark">
					<input type="text" :placeholder="$t('generalMessages.searchingArticle')" v-model="searchQuery" @keyup.enter="searchArticles" />
					<div class="icon search" @click="searchArticles"></div>
				</div>

				<div class="button outlined overDark showOnDesktop">
					<LocaleSwitcher />
				</div>

			</div>

		</div></div>

		<div class="menu"><div>

			<LocalizedLink class="option" :to="$t('routes.bateriesSystem')"><div class="text">{{ $t("nav.bateriesSystem") }}</div></LocalizedLink>
			<LocalizedLink class="option" :to="$t('routes.power')"><div class="text">{{ $t("nav.power") }}</div></LocalizedLink>
			<LocalizedLink class="option" :to="$t('routes.jardin')"><div class="text">{{ $t("nav.jardin") }}</div></LocalizedLink>
			<LocalizedLink class="option" :to="$t('routes.accesorios')"><div class="text">{{ $t("nav.accesorios") }}</div></LocalizedLink>
			<LocalizedLink class="option" :to="$t('routes.dealers')"><div class="text">{{ $t("nav.dealers") }}</div></LocalizedLink>

		</div></div>

	</div>
</template>

<script>
import LocaleSwitcher from "./LocaleSwitcher.vue"
import LocaleSwitcherMobile from "./LocaleSwitcherMobile.vue"
import LocalizedLink from "./LocalizedLink.vue"

import Tr from "@/translations"
import { useRouter, useRoute } from "vue-router"
import { ref } from "vue"

export default {
  name: 'HeaderComponent',
  components: { LocaleSwitcher, LocaleSwitcherMobile, LocalizedLink },
  setup() {
		
		const searchQuery = ref("");

		const route = useRoute()
		const router = useRouter()

		const closeNavBar = () => {
			return document.querySelector("#smallScreenMenuToggle").checked ? document.querySelector("#smallScreenMenuToggle").checked = false : ''
		}

		const subIsActive = (input) => {
			const paths = Array.isArray(input) ? input : [input]
			return paths.some(path => {
				return route.path.indexOf(path) === 0 // current path starts with this path string
			})
		}

		const searchArticles = () => {
			router.push({
                name: 'Searcher',
				params: { query: searchQuery.value }
            })

		}

        return { searchQuery, searchArticles, subIsActive, Tr, closeNavBar }
    }
}
</script>