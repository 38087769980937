import { ref } from "vue";
import GENERIC_VARIABLES from "../../config";
import Tr from "@/translations"



const getArticle = (subcategory,slug,id) => {

    const API = process.env.VUE_APP_ROOT_API+"articles/" + id + "?language="+Tr.currentLocale//+"&similares=false&relacionados=false&description=false&features=false&video=false"

    const item = ref(null)
    const error = ref(null)

    const load = async () => {
        try {
            let response = await fetch(API)
            
            let data = await response.json()

            if(data.error) throw Error("Article not exist")
            if (data.code != "200") throw Error("Article not exist")

            
            
            item.value = await data.results
            
            if(item.value.url[Tr.currentLocale]['subcategorySlug'] != subcategory) throw Error("Article not exist")
            if(item.value.url[Tr.currentLocale]['articleSlug'] != slug) throw Error("Article not exist")


        } catch (err) {
            error.value = err.message
        }

    }
    return { item, error, load } 

}

export default getArticle