<template>
    <div id="content" class="sections">
        <Head>
            <!-- Primary Meta Tags -->
            <title>{{ changeMetatagHtml($t("toolsOneForAll.metaTitle")) }}</title>
            
            <link rel="canonical" href="https://komatools.com/es/herramientas-one-for-all" />
            <link rel="alternate" href="https://komatools.com/es/herramientas-one-for-all" hreflang="es" />
            <link rel="alternate" href="https://komatools.com/en/one-for-all-tools" hreflang="en" />
            <link rel="alternate" href="https://komatools.com/fr/outils-one-for-all" hreflang="fr" />
            <link rel="alternate" href="https://komatools.com/es/herramientas-one-for-all" hreflang="x-default" />

            <meta name="title" :content="changeMetatagHtml($t('toolsOneForAll.metaTitle'))">

            <meta name="description" :content="$t('toolsOneForAll.metaDescription')">

            <!-- Open Graph / Facebook -->
            <meta property="og:type" content="website">
            <meta property="og:url" content="https://komatools.com/">
            <meta property="og:title" :content="changeMetatagHtml($t('toolsOneForAll.metaTitle'))">
            <meta property="og:description" :content="$t('toolsOneForAll.metaDescription')">
            <meta property="og:image" content="https://komatools.com/img/komatoolsSocial.jpg">

            <!-- Twitter -->
            <meta property="twitter:card" content="summary_large_image">
            <meta property="twitter:url" content="https://komatools.com/">
            <meta property="twitter:title" :content="changeMetatagHtml($t('toolsOneForAll.metaTitle'))">
            <meta property="twitter:description" :content="$t('toolsOneForAll.metaDescription')">
            <meta property="twitter:image" content="https://komatools.com/img/komatoolsSocial.jpg">
        </Head>

        <div class="section light">
            <div>
                <a class="button" @click="$router.back()">
                    <div class="icon back"></div>
                    <div class="text">{{ $t("nav.back") }}</div>
                </a>
                <div class="banner centered">
                    <div class="header">
                        <h1 class="title">{{ $t("toolsOneForAll.title") }}</h1>
                        <h2 v-html="$t('toolsOneForAll.subTitle')" class="subTitle"></h2>
                    </div>
                </div>
                <details>
                    <summary v-html="$t('toolsOneForAll.summary.title')"></summary>
                    <p v-html="$t('toolsOneForAll.summary.descriptionParagraph1')"></p>
                    <p v-html="$t('toolsOneForAll.summary.descriptionParagraph2')"></p>
                    <p v-html="$t('toolsOneForAll.summary.descriptionParagraph3')"></p>
                    <p v-html="$t('toolsOneForAll.summary.descriptionParagraph4')"></p>
                </details>
                <div class="banner centered" v-if="error">
                    <p v-html="$t(error)"></p>
                </div>
                <div v-else>
                    <ArticlesList :articles="searchedArticles" />
                </div>
            </div>
        </div>
    </div>
</template>


<script>
import getArticles from '../../composables/getArticles'
import GENERIC_VARIABLES from "../../../config";
import ArticlesList from '@/components/ArticlesList.vue'
import { Head } from '@vueuse/head'
import { computed, ref } from 'vue'
import Tr from "@/translations"


// Composition API and external JS
export default {
    name: "OneForAllTools",
    components: { ArticlesList, Head },
    setup() {
       
        const subcategories = []
        const filters = ["sistema:one for all"]


        const { articles, error, load } = getArticles(GENERIC_VARIABLES.PRODUCT_BRAND, subcategories, filters)

        load()

        /**
        * This is a function to remove the special character | from javascript
        * More information: https://stackoverflow.com/questions/5796718/html-entity-decode
        */
        const changeMetatagHtml = (html) => {
          let txt = document.createElement("textarea");
          txt.innerHTML = html;
          return txt.value;

        }

        const searchedArticles = computed(() => {

            return articles.value.filter((article) => {
                if (typeof article.name.es !== 'undefined' && article.name.es !== null) {
					return (
                        article.name.es
						.toLowerCase()
						.indexOf("kit") === -1
					);
                } else if (typeof article.name.ca !== 'undefined' && article.name.ca !== null) {
					return (
                        article.name.ca
						.toLowerCase()
						.indexOf("kit") === -1
					);
                } else if (typeof article.name.en !== 'undefined' && article.name.en !== null) {
					return (
                        article.name.en
						.toLowerCase()
						.indexOf("kit") === -1
					);
                } else if (typeof article.name.fr !== 'undefined' && article.name.fr !== null) {
					return (
                        article.name.fr
						.toLowerCase()
						.indexOf("kit") === -1
					);
                } else if (typeof article.name.pt !== 'undefined' && article.name.pt !== null) {
					return (
                        article.name.pt
						.toLowerCase()
						.indexOf("kit") === -1
					);
                }
                
			});
        });

        return { searchedArticles, error, GENERIC_VARIABLES, subcategories, changeMetatagHtml }
    }

}


</script>
