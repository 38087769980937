<template>
    <div class="accordion" id="accordionPanelsFaq">
        <div class="accordion-item">
            <h2 class="accordion-header" id="panelsStayOpen-headingOne">
                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseOne" aria-expanded="true" aria-controls="panelsStayOpen-collapseOne">
                    {{ $t('dealers.faq.question1') }}
                </button>
            </h2>
            <div id="panelsStayOpen-collapseOne" class="accordion-collapse collapse" aria-labelledby="panelsStayOpen-headingOne">
                <div class="accordion-body">
                    {{ $t('dealers.faq.answer1') }}
                </div>
            </div>
        </div>
        <div class="accordion-item">
            <h2 class="accordion-header" id="panelsStayOpen-headingTwo">
                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseTwo" aria-expanded="false" aria-controls="panelsStayOpen-collapseTwo">
                    {{ $t('dealers.faq.question2') }}
                </button>
            </h2>
            <div id="panelsStayOpen-collapseTwo" class="accordion-collapse collapse" aria-labelledby="panelsStayOpen-headingTwo">
                <div class="accordion-body">
                    {{ $t('dealers.faq.answer2') }}
                </div>
            </div>
        </div>
        <div class="accordion-item">
            <h2 class="accordion-header" id="panelsStayOpen-headingThree">
                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseThree" aria-expanded="false" aria-controls="panelsStayOpen-collapseThree">
                    {{ $t('dealers.faq.question3') }}
                </button>
            </h2>
            <div id="panelsStayOpen-collapseThree" class="accordion-collapse collapse" aria-labelledby="panelsStayOpen-headingThree">
                <div class="accordion-body">
                    {{ $t('dealers.faq.answer3') }}
                </div>
            </div>
        </div>
        <div class="accordion-item">
            <h2 class="accordion-header" id="panelsStayOpen-headingFour">
                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseFour" aria-expanded="false" aria-controls="panelsStayOpen-collapseFour">
                    {{ $t('dealers.faq.question4') }}
                </button>
            </h2>
            <div id="panelsStayOpen-collapseFour" class="accordion-collapse collapse" aria-labelledby="panelsStayOpen-headingFour">
                <div class="accordion-body">
                    {{ $t('dealers.faq.answer4') }}
                </div>
            </div>
        </div>
        <div class="accordion-item">
            <h2 class="accordion-header" id="panelsStayOpen-headingFive">
                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseFive" aria-expanded="false" aria-controls="panelsStayOpen-collapseFive">
                    {{ $t('dealers.faq.question5') }}
                </button>
            </h2>
            <div id="panelsStayOpen-collapseFive" class="accordion-collapse collapse" aria-labelledby="panelsStayOpen-headingFive">
                <div class="accordion-body">
                    {{ $t('dealers.faq.answer5') }}
                </div>
            </div>
        </div>
        <div class="accordion-item">
            <h2 class="accordion-header" id="panelsStayOpen-headingSix">
                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseSix" aria-expanded="false" aria-controls="panelsStayOpen-collapseSix">
                    {{ $t('dealers.faq.question6') }}
                </button>
            </h2>
            <div id="panelsStayOpen-collapseSix" class="accordion-collapse collapse" aria-labelledby="panelsStayOpen-headingSix">
                <div class="accordion-body">
                    {{ $t('dealers.faq.answer6') }}
                </div>
            </div>
        </div>
        <div class="accordion-item">
            <h2 class="accordion-header" id="panelsStayOpen-headingSeven">
                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseSeven" aria-expanded="false" aria-controls="panelsStayOpen-collapseSeven">
                    {{ $t('dealers.faq.question7') }}
                </button>
            </h2>
            <div id="panelsStayOpen-collapseSeven" class="accordion-collapse collapse" aria-labelledby="panelsStayOpen-headingSeven">
                <div class="accordion-body">
                    {{ $t('dealers.faq.answer7') }}
                </div>
            </div>
        </div>
        <div class="accordion-item">
            <h2 class="accordion-header" id="panelsStayOpen-headingEight">
                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseEight" aria-expanded="false" aria-controls="panelsStayOpen-collapseEight">
                    {{ $t('dealers.faq.question8') }}
                </button>
            </h2>
            <div id="panelsStayOpen-collapseEight" class="accordion-collapse collapse" aria-labelledby="panelsStayOpen-headingEight">
                <div class="accordion-body">
                    {{ $t('dealers.faq.answer8') }}
                </div>
            </div>
        </div>
        <div class="accordion-item">
            <h2 class="accordion-header" id="panelsStayOpen-headingNine">
                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseNine" aria-expanded="false" aria-controls="panelsStayOpen-collapseNine">
                    {{ $t('dealers.faq.question9') }}
                </button>
            </h2>
            <div id="panelsStayOpen-collapseNine" class="accordion-collapse collapse" aria-labelledby="panelsStayOpen-headingNine">
                <div class="accordion-body">
                    {{ $t('dealers.faq.answer9') }}
                </div>
            </div>
        </div>
        <div class="accordion-item">
            <h2 class="accordion-header" id="panelsStayOpen-headingTen">
                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseTen" aria-expanded="false" aria-controls="panelsStayOpen-collapseTen">
                    {{ $t('dealers.faq.question10') }}
                </button>
            </h2>
            <div id="panelsStayOpen-collapseTen" class="accordion-collapse collapse" aria-labelledby="panelsStayOpen-headingTen">
                <div class="accordion-body">
                    {{ $t('dealers.faq.answer10') }}
                </div>
            </div>
        </div>
        <div class="accordion-item">
            <h2 class="accordion-header" id="panelsStayOpen-headingEleven">
                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseEleven" aria-expanded="false" aria-controls="panelsStayOpen-collapseEleven">
                    {{ $t('dealers.faq.question11') }}
                </button>
            </h2>
            <div id="panelsStayOpen-collapseEleven" class="accordion-collapse collapse" aria-labelledby="panelsStayOpen-headingEleven">
                <div class="accordion-body">
                    {{ $t('dealers.faq.answer11') }}
                </div>
            </div>
        </div>
        <!-- Add FAQ schema to the footer element only when you are in this page -->
        <teleport to="#footer">
            <component :is="'script'" type="application/ld+json">
            {
                "@context": "https://schema.org",
                "@type": "FAQPage",
                "mainEntity": [
                    {
                        "@type": "Question",
                        "name": "{{ $t('dealers.faq.question1') }}",
                        "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "{{ $t('dealers.faq.answer1') }}"
                        }
                    }, {
                        "@type": "Question",
                        "name": "{{ $t('dealers.faq.question2') }}",
                        "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "{{ $t('dealers.faq.answer2') }}"
                        }
                    }, {
                        "@type": "Question",
                        "name": "{{ $t('dealers.faq.question3') }}",
                        "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "{{ $t('dealers.faq.answer3') }}"
                        }
                    }, {
                        "@type": "Question",
                        "name": "{{ $t('dealers.faq.question4') }}",
                        "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "{{ $t('dealers.faq.answer4') }}"
                        }
                    }, {
                        "@type": "Question",
                        "name": "{{ $t('dealers.faq.question5') }}",
                        "acceptedAnswer": {
                            "@type": "Answer",
                            "text":"{{ $t('dealers.faq.answer5') }}"
                        }
                    }, {
                        "@type": "Question",
                        "name": "{{ $t('dealers.faq.question6') }}",
                        "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "{{ $t('dealers.faq.answer6') }}"
                        }
                    }, {
                        "@type": "Question",
                        "name": "{{ $t('dealers.faq.question7') }}",
                        "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "{{ $t('dealers.faq.answer7') }}."
                        }
                    }, {
                        "@type": "Question",
                        "name": "{{ $t('dealers.faq.question8') }}",
                        "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "{{ $t('dealers.faq.answer8') }}"
                        }
                    }, {
                        "@type": "Question",
                        "name": "{{ $t('dealers.faq.question9') }}",
                        "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "{{ $t('dealers.faq.answer9') }}"
                        }
                    }, {
                        "@type": "Question",
                        "name": "{{ $t('dealers.faq.question10') }}",
                        "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "{{ $t('dealers.faq.answer10') }}"
                        }
                    }, {
                        "@type": "Question",
                        "name": "{{ $t('dealers.faq.question11') }}",
                        "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "{{ $t('dealers.faq.answer11') }}"
                        }
                    }
                ]
                
            }
            </component>
        </teleport>
    </div>
</template>

<script>
export default {
  name: 'FaqComponent'
}
</script>