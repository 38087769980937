<template>
    <div class="options buttons wrap centered">
        <!-- <input type="text" placeholder="Search..." v-model="searchQuery" /> -->
        <a class="button accent" v-for="(filter, index) in filtersRef" :key="index">
            <span
                @click="filterData(filter.code)"
                :class="{ active: filter.code === activeFilter }"
            >{{ $t(filter.text) }}</span>
        </a>
    </div>
</template>

<script>
import { ref } from 'vue'

export default {
    // name: "ButtonsFilter",
    props: ['filtersRef'],
    setup() {
        const activeFilter = ref('');

        const searchedArticles = computed(() => {
			if(searchQuery.value) {
				return articles.value.filter((article) => {
					return (
						article.name.es
						.toLowerCase()
						.indexOf(searchQuery.value.toLowerCase()) != -1
					);
				});
			} else {
				if (activeFilter.value === '') {
					return articles.value;
				}
				// return articles.value.filter((article) => article.subcategoryName.es.toLowerCase() === activeFilter.value.toLowerCase());
				return articles.value.filter((article) => {
					
					return (
						article.subcategoryCode  === activeFilter.value
						
					);
				});
			}
			
        });

		const filterData = (type) => {
			activeFilter.value = type;
		}

        return { activeFilter, searchedArticles, filterData }
    }
    
}
</script>

<style>

</style>