<template>

    <div id="content" class="sections">
        <Head>
            <!-- Primary Meta Tags -->
            <title>{{ changeMetatagHtml($t("dealers.metaTitle")) }}</title>

            <link rel="canonical" href="https://komatools.com/es/distribuidores" />
            <link rel="alternate" href="https://komatools.com/es/distribuidores" hreflang="es" />
            <link rel="alternate" href="https://komatools.com/en/dealers" hreflang="en" />
            <link rel="alternate" href="https://komatools.com/fr/distributeurs" hreflang="fr" />
            <link rel="alternate" href="https://komatools.com/es/distribuidores" hreflang="x-default" />

            <meta name="title" :content="changeMetatagHtml($t('dealers.metaTitle'))">
            
            <meta name="description" :content="$t('dealers.metaDescription')">

            <!-- Open Graph / Facebook -->
            <meta property="og:type" content="website">
            <meta property="og:url" content="https://komatools.com/">
            <meta property="og:title" :content="changeMetatagHtml($t('dealers.metaTitle'))">
            <meta property="og:description" :content="$t('dealers.metaDescription')">
            <meta property="og:image" content="https://komatools.com/img/komatoolsSocial.jpg">

            <!-- Twitter -->
            <meta property="twitter:card" content="summary_large_image">
            <meta property="twitter:url" content="https://komatools.com/">
            <meta property="twitter:title" :content="changeMetatagHtml($t('dealers.metaTitle'))">
            <meta property="twitter:description" :content="$t('dealers.metaDescription')">
            <meta property="twitter:image" content="https://komatools.com/img/komatoolsSocial.jpg">
        </Head>
        <div class="section">
            <div>
                <a class="button" @click="$router.back()">
                    <div class="icon back"></div>
                    <div class="text">{{ $t("nav.back") }}</div>
                </a>
            </div>
        </div>
        <div class="section light paddingHuge">
            <div>
                <div class="banner centered">
                    <div class="header">                        
                        <h1 class="title">{{ $t("dealers.title") }}</h1>
                        <h2 v-html="$t('dealers.subTitle')" class="subTitle"></h2>
                    </div>
                </div>
                <details>
                <summary v-html="$t('dealers.summary.title')"></summary>
                <p v-html="$t('dealers.summary.descriptionParagraph1')"></p>
                <p v-html="$t('dealers.summary.descriptionParagraph2')"></p>
                <p v-html="$t('dealers.summary.descriptionParagraph3')"></p>
                <p v-html="$t('dealers.summary.descriptionParagraph4')"></p>
                <p v-html="$t('dealers.summary.descriptionParagraph5')"></p>
            </details>
            </div>
        </div>
        
        <div class="section dark paddingHuge photoBackground lightPhoto" id="backgroud-map"><div>
			<div class="banner">
				<div class="header">
					<h3 class="subTitle">{{ $t('dealers.map.title') }}</h3>
				</div>
				<div class="buttons centered" style="width: 100%;">
					<a @click="gotoGoogleMaps()" class="button accent">
                        <div class="text">>{{ $t('dealers.map.buttonName') }}</div>
                        <div class="icon go"></div>
                    </a>
				</div>
			</div>

		</div></div>
        <div class="section light paddingHuge">
            <div>
                <div class="banner">
                    <div class="header">
                        <h2>{{ $t('dealers.faq.title') }}</h2>
                        <p>{{ $t('dealers.faq.subtitle') }}</p>
                    </div>
                </div>
                
                <FaqComponent/>
            </div>
        </div>

    </div>


  

</template>


<script>
import GENERIC_VARIABLES from "../../../config";
import FaqComponent from '@/components/FaqComponent.vue'
import { Head } from '@vueuse/head'



// Composition API and external JS
export default {
    name: "Dealers",
    components: { FaqComponent, Head },

    setup() {

        /**
		* This is a function to remove the special character | from javascript
		* More information: https://stackoverflow.com/questions/5796718/html-entity-decode
		 */
		const changeMetatagHtml = (html) => {
			let txt = document.createElement("textarea");
			txt.innerHTML = html;
			return txt.value;

		}

        const gotoGoogleMaps = ()  => {
            window.open("https://www.google.com/maps/d/embed?mid=1IpqNiVINJviqDfOslFCBOg7BivpRPrT0&ehbc=2E312F")
        }

        return { GENERIC_VARIABLES, gotoGoogleMaps, changeMetatagHtml }
    }

}
</script>
<style>
#backgroud-map {
	background-image: url('@/assets/img/komatools-distribuidores-background.map.jpg');
    width: 100%;
    background-position: center center;
}

#backgroud-map.section.photoBackground.lightPhoto:before {
	position: absolute;
	content: '';
	inset: 0;
	background: linear-gradient(270deg, rgba(60, 60, 60, 1), rgba(60, 60, 60, 1) 40%, rgba(0, 0, 0, 0) 75%);
	mix-blend-mode:hard-light;
}
</style>