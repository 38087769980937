<template>
  <ul class="language">
    <li v-for="sLocale in supportedLocales"
      :key="`locale-${sLocale}`">
      <a href="javascript:void(0)" @click="switchLanguageSelector(sLocale)">
        {{ t(`locale.${sLocale}`) }}
      </a>
    </li>
  </ul>
  <!-- <select @change="switchLanguageSelector">
    <option
      v-for="sLocale in supportedLocales"
      :key="`locale-${sLocale}`"
      :value="sLocale"
      :selected="locale === sLocale"
    >
      {{ t(`locale.${sLocale}`) }}
    </option>
  </select> -->
</template>

<script>
  import { useI18n } from 'vue-i18n'
  import { useRouter } from "vue-router"
  import Tr from "@/translations"

  export default {
    setup() {
      const { t, locale } = useI18n()

      const supportedLocales = Tr.supportedLocales

      const router = useRouter()

      const switchLanguageSelector = async (newLocale) => {
        // const newLocale = event.target.value
        // close the mobile menu
        document.querySelector("#smallScreenMenuToggle").checked ? document.querySelector("#smallScreenMenuToggle").checked = false : ''
        await Tr.switchLanguage(newLocale)
        try {
          // await router.replace({ params: { locale: newLocale } }) 
          await router.push(`/${newLocale}`)
        } catch(e) { 
          
          router.push("/")
        }
      }
      
      return { t, locale, supportedLocales, switchLanguageSelector }
    }
  }
</script>