import "bootstrap/dist/css/bootstrap.min.css"
import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import { createHead } from '@vueuse/head'
import VueEasyLightbox from 'vue-easy-lightbox'
// in this path vue-easy-lightbox/dist/external-css/*.js
// import VueEasyLightbox from 'vue-easy-lightbox/dist/external-css/vue-easy-lightbox.esm.min.js'

import '@/assets/styles/mosaic.css'
import '@/assets/styles/banner.css'
import '@/assets/styles/button.css'
import '@/assets/styles/cards.css'
import '@/assets/styles/content.css'
import '@/assets/styles/fonts.css'
import '@/assets/styles/icon.css'
import '@/assets/styles/labels.css'
import '@/assets/styles/main.css'
import '@/assets/styles/panelButtons.css'
import '@/assets/styles/productCards.css'
import '@/assets/styles/productProfile.css'
import '@/assets/styles/properties.css'
import '@/assets/styles/sectionDisclaimers.css'
import '@/assets/styles/sectionDouble.css'
import '@/assets/styles/sectionLinkGroups.css'
import '@/assets/styles/sectionLogo.css'
import '@/assets/styles/sectionCatalogue.css'
import '@/assets/styles/sectionParagraphs.css'
import '@/assets/styles/sections.css'
import '@/assets/styles/topBar.css'
import '@/assets/styles/tryptych.css'
import '@/assets/styles/variables.css'
import '@/assets/styles/list.css'
import '@/assets/styles/details.css'




const app = createApp(App)

app.use(i18n)
app.use(VueEasyLightbox)
app.use(createHead())
app.use(router)

// app.config.globalProperties.userLang = "es"//(navigator.language || navigator.userLanguage).substring(0, 2)
// app.config.globalProperties.$userLang = 'es'

app.mount('#app')

import "bootstrap/dist/js/bootstrap.js"
import i18n from './i18n'


// you need to import css yourself
// import 'vue-easy-lightbox/external-css/vue-easy-lightbox.css'
