<template>
    <div>
        <HeaderComponent/>
        <router-view :key="$route.path"/>
        <FooterComponent/>
        <CookieconsentComponent/>
        <MatomoComponent/>
    </div>
</template>
<script>
import HeaderComponent from './components/HeaderComponent.vue'
import FooterComponent from './components/FooterComponent.vue'
import CookieconsentComponent from './components/cookieConsent/CookieconsentComponent.vue'
import MatomoComponent from './components/matomo/MatomoComponent.vue'
import BreadcrumbComponent from "./components/BreadcrumbComponent.vue";
// import { useHead } from '@vueuse/head'
import { Head } from '@vueuse/head'


export default {
    name: 'App',
    components: {
        HeaderComponent,
        FooterComponent,
        CookieconsentComponent,
        MatomoComponent,
        Head
    },
    setup () {
        // useHead({
        //     // Can be static or computed
        //     title: 'Koma Tools herramientas eléctricas que se adaptan a ti | Koma Tools',
        //     meta: [
        //         {
        //             name: "description",
        //             content: 'Koma Tools es una marca de herramientas para bricolaje doméstico, en su gama Koma Tools y, para uso profesional, en su gama Koma Tools Pro. ¡Llámanos y te asesoramos! ☎ +34 977 79 29 45',
        //         },
        //         {
        //             name: "author",
        //             content: 'komatools.com',
        //         },
        //         {
        //             name: "keywords",
        //             content: 'komatools, koma tools, herramientas electricas, herramientas bricolaje, herramientas profesionales, herramientas de jardín',
        //         },
        //         {
        //             name: "copyright",
        //             content: 'komatools.com',
        //         }
        //     ]
        // })
    }
}

</script>
