
<template>
  <router-link :to="getTo()">
    <slot />
  </router-link>
</template>

<script>
import { useRoute } from "vue-router";

export default {
  props: ["to"],
  setup(props) {
    const route = useRoute()

    const getTo = () => {
      if (typeof props.to !== "string") {
        return props.to
      }
      const locale = route.params.locale

      // we strip leading and trailing slashes and prefix
      // the current locale
      return `/${locale}/${props.to.replace(/^\/|\/$/g, "")}`
    }
    return { getTo }

  }
}
</script>