<template>
    <div id="content" class="sections"> 

		<Head>
			<!-- Primary Meta Tags -->
			<title>{{ changeMetatagHtml($t("home.metaTitle")) }}</title>
			<meta name="title" :content="changeMetatagHtml($t('home.metaTitle'))">

			<link rel="canonical" href="https://komatools.com/es" />
			<link rel="alternate" href="https://komatools.com/es" hreflang="es" />
			<link rel="alternate" href="https://komatools.com/en" hreflang="en" />
			<link rel="alternate" href="https://komatools.com/fr" hreflang="fr" />
			<link rel="alternate" href="https://komatools.com/es" hreflang="x-default" />
			
			<meta name="description" :content="$t('home.metaDescription')">

			<!-- Open Graph / Facebook -->
			<meta property="og:type" content="website">
			<meta property="og:url" content="https://komatools.com/">
			<meta property="og:title" :content="changeMetatagHtml($t('home.metaTitle'))">
			<meta property="og:description" :content="$t('home.metaDescription')">
			<meta property="og:image" content="https://komatools.com/img/komatoolsSocial.jpg">

			<!-- Twitter -->
			<meta property="twitter:card" content="summary_large_image">
			<meta property="twitter:url" content="https://komatools.com/">
			<meta property="twitter:title" :content="changeMetatagHtml($t('home.metaTitle'))">
			<meta property="twitter:description" :content="$t('home.metaDescription')">
			<meta property="twitter:image" content="https://komatools.com/img/komatoolsSocial.jpg">
		</Head>

		<div class="section paddingBigger light double verticallyCentered"><div>

			<div class="tryptych">
				<img src="@/assets/img/photos/08374-08375.jpg" :alt="$t('mainSectionHome.title')" loading="lazy">
				<img src="@/assets/img/photos/08783_43.jpg" :alt="$t('mainSectionHome.subTitle')" loading="lazy">
				<img src="@/assets/img/photos/08375_1.jpg" :alt="$t('mainSectionHome.subTitle')" loading="lazy">
			</div>

			<div class="banner">
				<div class="header">
					<div class="title huge">{{ $t('mainSectionHome.title') }}</div>
					<div v-html="$t('mainSectionHome.subTitle')" class="subTitle"></div>
				</div>
				<div class="description">{{ $t('mainSectionHome.description') }}</div>
				<LocalizedLink class="button accent" :to="$t('routes.bateriesSystem')">
					<div class="icon go"></div>
					<div class="text">{{ $t("mainSectionHome.buttonName") }}</div>
				</LocalizedLink>
			</div>

		</div></div>

		<div class="section dark narrowLeft paddingHuge photoBackground lightPhoto" id="background6"><div>

			<div class="banner">
				<div class="header">
					<h1 class="title">{{ $t('photoBackgroundSectionHome.title') }}</h1>
					<div v-html="$t('photoBackgroundSectionHome.subTitle')" class="subTitle"></div>
				</div>
				<div v-html="$t('photoBackgroundSectionHome.description')" class="description"></div>
				<div class="buttons wrap centered">
					<LocalizedLink class="button accent" :to="$t('routes.proTools')">
						<div class="icon go"></div>
						<div class="text">{{ $t('photoBackgroundSectionHome.buttonName1') }}</div>
					</LocalizedLink>
					<LocalizedLink class="button accent" :to="$t('routes.domesticTools')">
						<div class="icon go"></div>
						<div class="text">{{ $t('photoBackgroundSectionHome.buttonName2') }}</div>
					</LocalizedLink>
				</div>
			</div>

		</div></div>

		<!-- <div class="section paddingBigger light"><div>&nbsp;</div></div> -->
		<div class="section paddingBigger light">
            <div>
                 
            </div>
        </div>

		<div class="section dark paddingHuge photoBackground lightPhoto double verticallyCentered" id="background7"><div>
			<div class="tryptych">
				
			</div>
			<div class="banner">
				<div class="header">
					<h1 class="title">{{ $t('photoBackgroundSectionHome2.title') }}</h1>
				</div>
				<div v-html="$t('photoBackgroundSectionHome2.description')" class="description"></div>
				<div class="buttons wrap centered">
					<LocalizedLink class="button accent" :to="$t('routes.measuringTools')">
						<div class="icon go"></div>
						<div class="text">{{ $t('photoBackgroundSectionHome2.buttonName1') }}</div>
					</LocalizedLink>
				</div>
			</div>

		</div></div>

		<div class="section light paddingBigger"><div>

			<div class="cards">

				<div class="card">
					<img src="@/assets/img/international.svg" :alt="$t('cardsHome.firstCard.title')">
					<div v-html="$t('cardsHome.firstCard.title')" class="text"></div>
				</div>

				<div class="card">
					<img src="@/assets/img/tool.svg" :alt="$t('cardsHome.secondCard.title')">
					<div v-html="$t('cardsHome.secondCard.title')" class="text"></div>
				</div>

				<div class="card">
					<img src="@/assets/img/original.svg" :alt="$t('cardsHome.thirdCard.title')">
					<div v-html="$t('cardsHome.thirdCard.title')" class="text"></div>
				</div>

			</div>

		</div></div>

		<topFeaturedArticles />

		<div class="section paddingBigger light"><div>

			<div class="panelButtons">

				<div class="panelButton">
					<div class="texts">
						<div v-html="$t('panelButtonsHome.firstPanel.title')" class="text"></div>
						<LocalizedLink class="button accent" :to="$t('routes.power')">
							<div class="text">{{ $t("panelButtonsHome.firstPanel.buttonName") }}</div>
							<div class="icon go"></div>
						</LocalizedLink>
					</div>
					<div class="photo" id="background2"></div>
				</div>

				<div class="panelButton">
					<div class="texts">
						<div v-html="$t('panelButtonsHome.secondPanel.title')" class="text"></div>
						<LocalizedLink class="button accent" :to="$t('routes.jardin')">
							<div class="text">{{ $t("panelButtonsHome.secondPanel.buttonName") }}</div>
							<div class="icon go"></div>
						</LocalizedLink>
					</div>
					<div class="photo" id="background3"></div>
				</div>

				<div class="panelButton">
					<div class="texts">
						<div v-html="$t('panelButtonsHome.thirdPanel.title')" class="text"></div>
						<LocalizedLink class="button accent" :to="$t('routes.cleaningTools')">
							<div class="text">{{ $t("panelButtonsHome.thirdPanel.buttonName") }}</div>
							<div class="icon go"></div>
						</LocalizedLink>
					</div>
					<div class="photo" id="background4"></div>
				</div>

				<div class="panelButton">
					<div class="texts">
						<div v-html="$t('panelButtonsHome.fourthPanel.title')" class="text"></div>
						<LocalizedLink class="button accent" :to="$t('routes.accesorios')">
							<div class="text">{{ $t("panelButtonsHome.fourthPanel.buttonName") }}</div>
							<div class="icon go"></div>
						</LocalizedLink>
					</div>
					<div class="photo" id="background5"></div>
				</div>
				<div class="panelButton">
					<div class="texts">
						<div v-html="$t('panelButtonsHome.fifthPanel.title')" class="text"></div>
						<LocalizedLink class="button accent" :to="$t('routes.drillBits')">
							<div class="text">{{ $t("panelButtonsHome.fifthPanel.buttonName") }}</div>
							<div class="icon go"></div>
						</LocalizedLink>
					</div>
					<div class="photo" id="background8"></div>
				</div>

				<div class="panelButton">
					<div class="texts">
						<div v-html="$t('panelButtonsHome.sixthPanel.title')" class="text"></div>
						<LocalizedLink class="button accent" :to="$t('routes.cuttingTools')">
							<div class="text">{{ $t("panelButtonsHome.sixthPanel.buttonName") }}</div>
							<div class="icon go"></div>
						</LocalizedLink>
					</div>
					<div class="photo" id="background9"></div>
				</div>
			</div>

		</div></div>

		<div class="section paddingBigger light">
            <div>
                 <div class="banner">
                    <div class="header">
                        <h2 class="title">{{ $t('home.whyBuyKomaTools.why') }}</h2>
                    </div>
                </div>
				<div v-html="$t('home.whyBuyKomaTools.because')" class="description"></div>
            </div>
        </div>
		<div class="section paddingBottom light double verticallyCentered">
            <div>
                <img src="@/assets/img/KOMA-family-blau-verd.jpg" :alt="$t('home.whyBuyKomaTools.why')">

                <div class="list">
                    <div class="item">
                        <div class="icon go"></div>
                        <div class="title">{{ $t('home.whyBuyKomaTools.quality') }}</div>
                        <div class="text">{{ $t('home.whyBuyKomaTools.qualityText') }}</div>
                    </div>
                    <div class="item">
                        <div class="icon go"></div>
                        <div class="title">{{ $t('home.whyBuyKomaTools.price') }}</div>
                        <div class="text">{{ $t('home.whyBuyKomaTools.priceText') }}</div>
                    </div>
                    <div class="item">
                        <div class="icon go"></div>
                        <div class="title">{{ $t('home.whyBuyKomaTools.finish') }}</div>
                        <div class="text">{{ $t('home.whyBuyKomaTools.finishText') }}</div>
                    </div>
                    <div class="item">
                        <div class="icon go"></div>
                        <div class="title">{{ $t('home.whyBuyKomaTools.spareParts') }}</div>
                        <div class="text">{{ $t('home.whyBuyKomaTools.sparePartsText') }}</div>
                    </div>
                </div>
		    </div>
        </div>

	</div>
</template>

<script>

import topFeaturedArticles from '@/components/topFeaturedArticles.vue'
import { Head } from '@vueuse/head'
import LocalizedLink from "@/components/LocalizedLink.vue"




export default {
    name: "HomeView",
    components: { topFeaturedArticles, Head, LocalizedLink },

	setup() {
		/**
		* This is a function to remove the special character | from javascript
		* More information: https://stackoverflow.com/questions/5796718/html-entity-decode
		 */
		const changeMetatagHtml = (html) => {
			let txt = document.createElement("textarea");
			txt.innerHTML = html;
			return txt.value;

		}
        return { changeMetatagHtml }
	}


	
}
</script>


<style>
#background1 {
	background-image: url('@/assets/img/photos/08760_41.jpg');
}
#background2 {
	background-image: url('@/assets/img/photos/08703_40.jpg');
}
#background3 {
	background-image: url('@/assets/img/photos/08339_40.jpg');
}
#background4 {
	background-image: url('@/assets/img/photos/08680_40.jpg');
}
#background5 {
	background-image: url('@/assets/img/photos/08785_41.jpg');
}
#background6 {
	/* background-image: url('@/assets/img/photos/08724_40.jpg'); */
	background-image: url('@/assets/img/08724_42.jpg');
	/* width: 100%;
    background-position: center center; */
}
#background7 {
	background-image: url('@/assets/img/NT8A2678-Editar.jpg');
}
#background7.section.photoBackground.lightPhoto:before {
	position: absolute;
	content: '';
	inset: 0;
	background: linear-gradient(270deg, rgba(60, 60, 60, 1), rgba(60, 60, 60, 1) 40%, rgba(0, 0, 0, 0) 75%);
	mix-blend-mode:hard-light;
}
#background8 {
	background-image: url('@/assets/img/photos/brocas_2.jpg');
}
#background9 {
	background-image: url('@/assets/img/photos/cutters.jpg');
}
</style>
