import { createRouter, createWebHistory, RouterView } from 'vue-router'
import Tr from "@/translations"
import i18n from "@/i18n"

import HomeView from '../views/HomeView.vue'
import AboutView from '../views/AboutView.vue'
import ArticleDetailsView from '../views/sections/ArticleDetailsView.vue'
import NotFoundView from '../views/NotFoundView.vue'
// import NotFoundView301 from '../views/NotFoundView301.vue'
import AllArticles from '../views/sections/AllArticles.vue'
import PowerTools from '../views/sections/PowerTools.vue'
import GardenTools from '../views/sections/GardenTools.vue'
import Accessories from '../views/sections/Accessories.vue'
import Bricolage from '../views/sections/Bricolage.vue'
import CleaningTools from '../views/sections/CleaningTools.vue'
import SpareParts from '../views/sections/SpareParts.vue'
import Consumables from '../views/sections/Consumables.vue'
import BateriesAndCargers from '../views/sections/BateriesAndCargers.vue'
import Dealers from '../views/sections/Dealers.vue'
import BestSellers from '../views/sections/BestSellers.vue'
import GasolineTools from'../views/sections/GasolineTools.vue'
import BateryTools from'../views/sections/BateryTools.vue'
import ProTools from'../views/sections/ProTools.vue'
import DomesticTools from'../views/sections/DomesticTools.vue'
import Novelties from'../views/sections/Novelties.vue'
import CordedTools from '../views/sections/CordedTools.vue'
import Searcher from '../views/sections/Searcher.vue'
import BateriesSystem from '../views/sections/BateriesSystem'
import StarterKits from '../views/sections/StarterKits'
import OneForAllTools from '../views/sections/OneForAllTools'
import OneForAllToolsCompleted from '../views/sections/OneForAllToolsCompleted'
import DataProtection from '../views/sections/DataProtection'
import MeasuringTools from '../views/sections/MeasuringTools'
import DrillBits from '../views/sections/DrillBits'
import CuttingTools from '../views/sections/CuttingTools'

const routes = [
    {
      path: "/",
      redirect: i18n.global.locale.value
    },
    {
      path: "/:locale",
      name: 'home',
      component: RouterView,
      beforeEnter: Tr.routeMiddleware,
      children: [
        {
          path: '',
          name: 'inicio',
          component: HomeView
        },
        // {
        //   path: 'que-es-koma-tools',
        //   name: 'About',
        //   component: AboutView
        // },
        {
          path: 'sistema-de-baterias-intercambiables',
          name: 'BateriesSystem',
          component: BateriesSystem,
          alias: ['sistema-de-bateries-intercanviables','interchangeable-battery-system', 'systeme-de-batterie-interchangeable', 'sistema-de-baterias-intercambiaveis'],
          meta: {toTop: true, smoothScroll: true}
        },
        {
          path: 'herramientas-electricas',
          name: 'PowerTools',
          component: PowerTools,
          alias: ['eines-electriques','power-tools','outillage-electriques', 'ferramentas-electricas'],
          meta: {toTop: true, smoothScroll: true}
        },
        {
          path: 'herramientas-de-jardin',
          name: 'GardenTools',
          component: GardenTools,
          alias: ['eines-de-jardi','garden-tools', 'outils-de-jardinage', 'ferramentas-de-jardim'],
          meta: {toTop: true, smoothScroll: true}
        },
        {
          path: 'accesorios',
          name: 'Accessories',
          component: Accessories,
          alias: ['accessoris','accessories', 'accessoires', 'acessorios'],
          meta: {toTop: true, smoothScroll: true}
        },
        {
          path: 'brocas',
          name: 'DrillBits',
          component: DrillBits,
          alias: ['broques','drill-bits', 'forets', 'brocas'],
          meta: {toTop: true, smoothScroll: true}
        },
        {
          path: 'herramientas-de-corte',
          name: 'CuttingTools',
          component: CuttingTools,
          alias: ['eines-de-tall','cutting-tools', 'outils-de-coupe', 'ferramentas-de-corte'],
          meta: {toTop: true, smoothScroll: true}
        },
        {
          path: 'herramientas-de-bricolaje',
          name: 'Bricolage',
          component: Bricolage,
          alias: ['eines-de-bricolatge','bricolage-tools', 'outillage-pour-le-bricolage', 'ferramentas-para-bricolagem'],
          meta: {toTop: true, smoothScroll: true}
        },
        {
          path: 'herramientas-de-limpieza',
          name: 'CleaningTools',
          component: CleaningTools,
          alias: ['eines-de-neteja','cleaning-tools', 'outils-de-nettoyage', 'ferramentas-de-limpeza'],
          meta: {toTop: true, smoothScroll: true}
        },
        {
          path: 'recambios',
          name: 'SpareParts',
          component: SpareParts,
          alias: ['recanvis','spare-parts', 'pieces-detachees', 'sobresselentes'],
          meta: {toTop: true, smoothScroll: true}
        },  
        {
          path: 'consumibles',
          name: 'Consumables',
          component: Consumables,
          alias: ['consumibles','consumables', 'consommables', 'consumiveis'],
          meta: {toTop: true, smoothScroll: true}
        },
        {
          path: 'baterias-y-cargadores',
          name: 'BateriesAndCargers',
          component: BateriesAndCargers,
          alias: ['bateries-i-carregadors','batteries-and-chargers', 'batteries-et-chargeurs', 'baterias-e-carregadores'],
          meta: {toTop: true, smoothScroll: true}
        },
        { 
          path: 'los-mas-vendidos',
          name: 'BestSellers',
          component: BestSellers,
          alias: ['els-mes-venuts','best-sellers', 'meilleures-ventes', 'os-mais-vendidos'],
          meta: {toTop: true, smoothScroll: true}
        },
        { 
          path: 'novedades',
          name: 'Novelties',
          component: Novelties,
          alias: ['novetats','novelties'],
          meta: {toTop: true, smoothScroll: true}
        },
        {
          path: 'distribuidores',
          name: 'Dealers',
          component: Dealers,
          alias: ['distribuidors','dealers', 'distributeurs'],
          meta: {toTop: true, smoothScroll: true}
        },
        {
          path: 'catalogo-de-herramientas',
          name: 'AllArticles',
          component: AllArticles,
          alias: ['cataleg-de-eines','all-tools', 'catalogue-outils', 'catalogo-de-ferramentas'],
          meta: {toTop: true, smoothScroll: true}
        },
        {
          path: 'herramientas-de-gasolina',
          name: 'GasolineTools',
          component: GasolineTools,
          alias: ['eines-de-gasolina','gasoline-tools', 'outils-a-essence', 'ferramentas-a-gasolina'],
          meta: {toTop: true, smoothScroll: true}
        },
        {
          path: 'herramientas-a-bateria',
          name: 'BateryTools',
          component: BateryTools,
          alias: ['eines-a-bateria','batery-power-tools', 'outils-a-batterie', 'ferramentas-a-bateria'],
          meta: {toTop: true, smoothScroll: true}
        },
        {
          path: 'herramientas-con-cable',
          name: 'CordedTools',
          component: CordedTools,
          alias: ['eines-amb-cable','corded-power-tools', 'outils-a-cordon', 'ferramentas-com-cabo-de-alimentacao'],
          meta: {toTop: true, smoothScroll: true}
        },
        {
          path: 'herramientas-para-profesionales',
          name: 'ProTools',
          component: ProTools,
          alias: ['eines-per-professionals','profesional-tools', 'outillage-pour-professionnels', 'ferramentas-para-profissionais'],
          meta: {toTop: true, smoothScroll: true}
        },
        {
          path: 'herramientas-para-bricolaje-domestico',
          name: 'DomesticTools',
          component: DomesticTools,
          alias: ['eines-per-bricolatge-domestic','home-bricolage-tools', 'outillage-pour-le-bricolage-a-domicile', 'ferramentas-para-bricolagem-em-casa'],
          meta: {toTop: true, smoothScroll: true}
        },
        {
          path: 'kits-inicio-one-for-all',
          name: 'StarterKits',
          component: StarterKits,
          alias: ['kits-inici-one-for-all','starter-kits-one-for-all', 'kits-de-demarrage-one-for-all', 'kits-de-inicio-one-for-all'],
          meta: {toTop: true, smoothScroll: true}
        },
        {
          path: 'herramientas-one-for-all',
          name: 'OneForAllTools',
          component: OneForAllTools,
          alias: ['eines-one-for-all','one-for-all-tools', 'outils-one-for-all', 'ferramentas-one-for-all'],
          meta: {toTop: true, smoothScroll: true}
        },
        {
          path: 'sistema-one-for-all',
          name: 'OneForAllToolsCompleted',
          component: OneForAllToolsCompleted,
          alias: ['sistema-one-for-all','one-for-all-system', 'systeme-one-for-all'],
          meta: {toTop: true, smoothScroll: true}
        },
        {
          path: 'herramientas-de-medicion',
          name: 'MeasuringTools',
          component: MeasuringTools,
          alias: ['eines-per-mesurar','measuring-tools','outils-de-mesure', 'ferramentas-de-medicao'],
          meta: {toTop: true, smoothScroll: true}
        },
        {
          path: 'proteccion-de-datos',
          name: 'DataProtection',
          component: DataProtection,
          alias: ['proteccio-de-dades','data-protection', 'protection-des-donnees', 'protecao-de-dados'],
          meta: {toTop: true, smoothScroll: true}
        },
        {
          path: 'search/:query',
          name: 'Searcher',
          component: Searcher,
          props: true,
          meta: {toTop: true, smoothScroll: true}
        },
        {
          path: ':subcategory/:slug/:id',
          name: 'ArticleDetails',
          component: ArticleDetailsView,
          props: true,
          meta: {toTop: true, smoothScroll: true}
        }
      ]
    }
    
  
  ,
  // {
  //   // path: '/:catchAll(.*)',
  //   path: '/:pathMatch(.*)*',
  //   name: 'NotFound',
  //   component: NotFoundView,
  //   meta: {toTop: true, smoothScroll: true}
  // },

  // { path: '/301', name: 'NotFound301', component: NotFoundView301 , redirectParams: { 
  //   responseCode: 301 
  // } },
  { path: '/404', name: 'NotFound', component: NotFoundView },
  { path: '/:pathMatch(.*)*', 
    beforeEnter (to) {
      window.location = `/404`
    }
  }
]





const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to) {
    const scroll = {}
    if(to.meta.toTop) scroll.top = 0
    if(to.meta.smoothScroll) scroll.behavior = 'smooth'
    return scroll
  }
})

router.beforeEach((to, from, next) => {

  if(!to.params.locale) {
    to.params.locale = i18n.global.locale.value
  }
  if (to.params.locale === from.params.locale) {
    next()
    return
  }

  const { locale } = to.params
  
  Tr.switchLanguage(locale)
  next()
})

export default router
