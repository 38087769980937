<template>
    <div id="content" class="sections">
        <Head>
            <!-- Primary Meta Tags -->
            <title>{{ changeMetatagHtml($t("bateriesSystem.metaTitle")) }}</title>

            <link rel="canonical" href="https://komatools.com/es/sistema-de-baterias-intercambiables" />
            <link rel="alternate" href="https://komatools.com/es/sistema-de-baterias-intercambiables" hreflang="es" />
            <link rel="alternate" href="https://komatools.com/en/interchangeable-battery-system" hreflang="en" />
            <link rel="alternate" href="https://komatools.com/fr/systeme-de-batterie-interchangeable" hreflang="fr" />
            <link rel="alternate" href="https://komatools.com/es/sistema-de-baterias-intercambiables" hreflang="x-default" />

            <meta name="title" :content="changeMetatagHtml($t('bateriesSystem.metaTitle'))">
            
            <meta name="description" :content="$t('bateriesSystem.metaDescription')">

            <!-- Open Graph / Facebook -->
            <meta property="og:type" content="website">
            <meta property="og:url" content="https://komatools.com/">
            <meta property="og:title" :content="changeMetatagHtml($t('bateriesSystem.metaTitle'))">
            <meta property="og:description" :content="$t('bateriesSystem.metaDescription')">
            <meta property="og:image" content="https://komatools.com/img/komatoolsSocial.jpg">

            <!-- Twitter -->
            <meta property="twitter:card" content="summary_large_image">
            <meta property="twitter:url" content="https://komatools.com/">
            <meta property="twitter:title" :content="changeMetatagHtml($t('bateriesSystem.metaTitle'))">
            <meta property="twitter:description" :content="$t('bateriesSystem.metaDescription')">
            <meta property="twitter:image" content="https://komatools.com/img/komatoolsSocial.jpg">
        </Head>
        <div class="section light">
            <div>
                <a class="button" @click="$router.back()">
                    <div class="icon back"></div>
                    <div class="text">{{ $t("nav.back") }}</div>
                </a>       
            </div>
        </div>
        <div class="section paddingBigger light verticallyCentered">
            <div>
                <div class="banner cetered">
                    <div class="header">
                        <h1 class="title huge">{{ $t('bateriesSystem.title') }}</h1>
                        <h2 v-html="$t('bateriesSystem.subTitle')" class="subTitle"></h2>
                    </div>
                    <div class="description">{{ $t('bateriesSystem.description') }}</div>
                    <div class="cta">{{ $t('bateriesSystem.cta1') }}</div>
                    <div style="display: flex;justify-content: center;width: 100%;padding: 10px 0;">
                        <img src="@/assets/img/20V.jpg" alt="20 V ONE FOR ALL Battery System">
                    </div>
                    
                </div>
		    </div>
        </div>
        <div class="section dark narrowLeft paddingHuge photoBackground lightPhoto" id="backgroundBateriesSystem4"><div>

			<div class="banner">
				<div class="header">
					<h2 class="title">{{ $t('bateriesSystem.cta2') }}</h2>
				</div>
				<div v-html="$t('bateriesSystem.textCta2')" class="description"></div>
			</div>

		</div></div>
        <div class="section paddingBigger light">
            <div>
                 <div class="banner">
                    <div class="header">
                        <h2 class="title">{{ $t('bateriesSystem.cta3') }}</h2>
                    </div>
                </div>
            </div>
        </div>
        <div class="section paddingBigger light double verticallyCentered">
            <div>
                <img src="@/assets/img/photos/bateria-explote.jpg" :alt="$t('bateriesSystem.cta3')+' | '+$t('bateriesSystem.firstCard.title')+' | '+$t('bateriesSystem.secondCard.title')+' | '+$t('bateriesSystem.thirdCard.title')">

                <div class="list">
                    <div class="item">
                        <div class="icon go"></div>
                        <div class="title">{{ $t('bateriesSystem.bateriesCharacteristics.characteristic1.title') }}</div>
                        <div class="text">{{ $t('bateriesSystem.bateriesCharacteristics.characteristic1.text') }}</div>
                    </div>
                    <div class="item">
                        <div class="icon go"></div>
                        <div class="title">{{ $t('bateriesSystem.bateriesCharacteristics.characteristic2.title') }}</div>
                        <div class="text">{{ $t('bateriesSystem.bateriesCharacteristics.characteristic2.text') }}</div>
                    </div>
                    <div class="item">
                        <div class="icon go"></div>
                        <div class="title">{{ $t('bateriesSystem.bateriesCharacteristics.characteristic3.title') }}</div>
                        <div class="text">{{ $t('bateriesSystem.bateriesCharacteristics.characteristic3.text') }}</div>
                    </div>
                    <div class="item">
                        <div class="icon go"></div>
                        <div class="title">{{ $t('bateriesSystem.bateriesCharacteristics.characteristic4.title') }}</div>
                        <div class="text">{{ $t('bateriesSystem.bateriesCharacteristics.characteristic4.text') }}</div>
                    </div>
                    <div class="item">
                        <div class="icon go"></div>
                        <div class="title">{{ $t('bateriesSystem.bateriesCharacteristics.characteristic5.title') }}</div>
                        <div class="text">{{ $t('bateriesSystem.bateriesCharacteristics.characteristic5.text') }}</div>
                    </div>
                    <div class="item">
                        <div class="icon go"></div>
                        <div class="title">{{ $t('bateriesSystem.bateriesCharacteristics.characteristic6.title') }}</div>
                        <div class="text">{{ $t('bateriesSystem.bateriesCharacteristics.characteristic6.text') }}</div>
                    </div>

                </div>
		    </div>
        </div>
        <div class="section light paddingBigger"><div>
			<div class="cards">
				<div class="card">
                    <img src="@/assets/icons/toolbox.svg" :alt="$t('bateriesSystem.firstCard.title')">
					<div class="text"><b>{{ $t('bateriesSystem.firstCard.title') }}</b></div>
					<div v-html="$t('bateriesSystem.firstCard.text')" class="description"></div>
				</div>

				<div class="card">
					<img src="@/assets/icons/ecologic.svg" :alt="$t('bateriesSystem.firstCard.title')">
					<div class="text"><b>{{ $t('bateriesSystem.secondCard.title') }}</b></div>
					<div v-html="$t('bateriesSystem.secondCard.text')" class="description"></div>
				</div>

				<div class="card">
					<img src="@/assets/icons/battery.svg" :alt="$t('bateriesSystem.firstCard.title')">
					<div class="text"><b>{{ $t('bateriesSystem.thirdCard.title') }}</b></div>
					<div v-html="$t('bateriesSystem.thirdCard.text')" class="description"></div>
				</div>

			</div>

		</div></div>
        <div class="section dark narrowLeft paddingHuge photoBackground lightPhoto" id="backgroundBateriesSystem1"><div>

			<div class="banner centered">
				<div class="header">
					<h2 class="title" v-html="$t('bateriesSystem.cta4')" style="white-space: nowrap;"></h2>
				</div>
				<div v-html="$t('bateriesSystem.textCta4')" class="description"></div>
				<div v-html="$t('bateriesSystem.description1Cta4')" class="description"></div>
				<div v-html="$t('bateriesSystem.description2Cta4')" class="description"></div>
			</div>

		</div></div>
        <div class="section paddingBigger light"><div>
            <div class="banner centered" style="width: 100%;">
				<div class="header">
					<h2 class="title">{{ $t('bateriesSystem.cta5') }}</h2>
				</div>
            </div>

			<div class="panelButtons">

				<div class="panelButton">
					<div class="texts">
						<div v-html="$t('bateriesSystem.firstPanel.title')" class="text"></div>
						<LocalizedLink class="button accent" :to="$t('routes.initialKids')">
							<div class="text">{{ $t("bateriesSystem.firstPanel.buttonName") }}</div>
							<div class="icon go"></div>
						</LocalizedLink>
					</div>
					<div class="photo" id="backgroundBateriesSystem2"></div>
				</div>

				<div class="panelButton">
					<div class="texts">
						<div v-html="$t('bateriesSystem.secondPanel.title')" class="text"></div>
						<LocalizedLink class="button accent" :to="$t('routes.oneForAllTools')">
							<div class="text">{{ $t("bateriesSystem.secondPanel.buttonName") }}</div>
							<div class="icon go"></div>
						</LocalizedLink>
					</div>
					<div class="photo" id="backgroundBateriesSystem3"></div>
				</div>

				

			</div>

		</div></div>
    </div>
</template>

<script>

import LocalizedLink from "@/components/LocalizedLink.vue"
import { Head } from '@vueuse/head'


export default {
    components: { LocalizedLink, Head },
    setup() {
        const blocks =  [
            {
            img: require('@/assets/img/photos/08750_41.jpg'),
            },
            {
            img: require('@/assets/img/photos/08752_40.jpg')
            },
            {
            img: require('@/assets/img/photos/08752_41.jpg')
            },
            {
            img: require('@/assets/img/photos/08753_40.jpg')
            },
            {
            img: require('@/assets/img/photos/08754_40.jpg')
            },
            {
            img: require('@/assets/img/photos/08755_40.jpg')
            },
            {
            img: require('@/assets/img/photos/08756_40.jpg')
            },
            {
            img: require('@/assets/img/photos/08756_43.jpg')
            },
            {
            img: require('@/assets/img/photos/08757_40.jpg')
            },
            {
            img: require('@/assets/img/photos/08758_40.jpg')
            },
            {
            img: require('@/assets/img/photos/08759_40.jpg')
            },
            {
            img: require('@/assets/img/photos/08760_41.jpg')
            },
            {
            img: require('@/assets/img/photos/IMG_5931.jpg')
            },
            {
            img: require('@/assets/img/photos/08761_42.jpg')
            },
            {
            img: require('@/assets/img/photos/08762_40.jpg')
            },
            {
            img: require('@/assets/img/photos/08763_40.jpg')
            },
            {
            img: require('@/assets/img/photos/08763_42.jpg')
            },
            {
            img: require('@/assets/img/photos/08764_42.jpg')
            },
            {
            img: require('@/assets/img/photos/08765_41.jpg')
            },
            {
            img: require('@/assets/img/photos/08765_42.jpg')
            },
            {
            img: require('@/assets/img/photos/08767_40.jpg')
            },
            {
            img: require('@/assets/img/photos/08767_41.jpg')
            },
            {
            img: require('@/assets/img/photos/08769_40.jpg')
            },
            {
            img: require('@/assets/img/photos/08780_41.jpg')
            },
            {
            img: require('@/assets/img/photos/08782_43.jpg')
            },
            {
            img: require('@/assets/img/photos/08782_44.jpg')
            },
            {
            img: require('@/assets/img/photos/08783_43.jpg')
            },
            {
            img: require('@/assets/img/photos/08339_42.jpg')
            },
            {
            img: require('@/assets/img/photos/08750_42.jpg')
            }

      ]

      /**
		* This is a function to remove the special character | from javascript
		* More information: https://stackoverflow.com/questions/5796718/html-entity-decode
		 */
		const changeMetatagHtml = (html) => {
			let txt = document.createElement("textarea");
			txt.innerHTML = html;
			return txt.value;

		}
      return { blocks, changeMetatagHtml }
    },
}
</script>


<style>
.banner .header > .subTitle {
    line-height: 1.3em !important;
}
.banner1 > img {
    float: left;
    width: 90%;
    border-radius: var(--radiusFat);
    -o-object-fit: cover;
    object-fit: cover;
}

img.mosaicItem {
    height: auto;
    width: 100%;
}




.cards > .card {
    max-width: 300px;
}

#backgroundBateriesSystem1 {
	background-image: url('@/assets/img/photos/IMG_5922.jpg');
}
#backgroundBateriesSystem2 {
	background-image: url('@/assets/img/photos/08750_40.jpg');
}
#backgroundBateriesSystem3 {
	background-image: url('@/assets/img/photos/08339_42.jpg');
}
#backgroundBateriesSystem4 {
	background-image: url('@/assets/img/products-mosaic-background.jpg');
    background-position: right;
    background-position-x: 95%;
    background-size: contain;
    background-repeat: no-repeat;
}
    


</style>
