<template>
    <div id="content" class="sections">
        <Head>
			<!-- Primary Meta Tags -->
			<title>{{ item?.name[Tr.currentLocale] ? item?.name[Tr.currentLocale] : item?.name["es"] }}</title>
			<meta name="title" :content="item?.name[Tr.currentLocale] ? item?.name[Tr.currentLocale] : item?.name['es']">
            <link rel="canonical" :href="'https://komatools.com/es/'+item?.url['es']['subcategorySlug']+'/'+item?.url['es']['articleSlug']+'/'+item?.id" />
            <link rel="alternate" :href="'https://komatools.com/es/'+item?.url['es']['subcategorySlug']+'/'+item?.url['es']['articleSlug']+'/'+item?.id" hreflang="es" />
            <link rel="alternate" :href="'https://komatools.com/en/'+item?.url['en']['subcategorySlug']+'/'+item?.url['en']['articleSlug']+'/'+item?.id" hreflang="en" />
            <link rel="alternate" :href="'https://komatools.com/es/'+item?.url['es']['subcategorySlug']+'/'+item?.url['es']['articleSlug']+'/'+item?.id" hreflang="x-default" />

			
			<meta name="description" :content="item?.description[Tr.currentLocale] ? item?.description[Tr.currentLocale] : item?.description['es']">

			<!-- Open Graph / Facebook -->
			<meta property="og:type" content="website">
			<meta property="og:url" content="https://komatools.com/">
			<meta property="og:title" :content="item?.name[Tr.currentLocale] ? item?.name[Tr.currentLocale] : item?.name['es']">
			<meta property="og:description" :content="item?.description[Tr.currentLocale] ? item?.description[Tr.currentLocale] : item?.description['es']">
			<meta property="og:image" content="https://komatools.com/img/komatoolsSocial.jpg">

			<!-- Twitter -->
			<meta property="twitter:card" content="summary_large_image">
			<meta property="twitter:url" content="https://komatools.com/">
			<meta property="twitter:title" :content="item?.name[Tr.currentLocale] ? item?.name[Tr.currentLocale] : item?.name['es']">
			<meta property="twitter:description" :content="item?.description[Tr.currentLocale] ? item?.description[Tr.currentLocale] : item?.description['es']">
			<meta property="twitter:image" content="https://komatools.com/img/komatoolsSocial.jpg">
		</Head>

        <div class="section paddingBigger light">
            <div>
                <div class="productProfile" v-if="item">

                    <div class="images">

                        <div class="mainImage">
                            <div v-if="item.images.length">
                                <div v-for="(src, index) in item.images" :class="[{ mainImage: index === 0 }, {hide: index > 0}]"
                                :key="index" @click="() => showImg(index)">
                                    <img :src="apiUrl+src" :alt="item.name[Tr.currentLocale] ? item.name[Tr.currentLocale] : item.name['es']" />
                                </div>
                            </div>
                            <div v-else>
                                <div class="mainImage">
                                    <img :src="apiUrl+item.image" :alt="item.name[Tr.currentLocale] ? item.name[Tr.currentLocale] : item.name['es']" @click="() => showImg(0)" />
                                </div>
                            </div>

                        </div>

                        <div class="thumbnails">
                            <div v-if="item.thumbnails.length">
                                <div v-for="(src, index) in item.thumbnails" class="thumbnail"
                                :style="getThumbnailImage(src)"
                                :key="index" @click="() => showImg(index)">
                                </div>
                            </div>
                            <div v-else>
                                <div class="thumbnail"
                                :style="getThumbnailImage(item.image)" @click="() => showImg(0)">
                                </div>
                            </div>
                        </div>

                    </div>

                    <div class="details">

                            <h1 class="title">
                                {{ item.name[Tr.currentLocale] ? item.name[Tr.currentLocale] : item.name["es"] }}
                            </h1>

                            <div class="labels">
                                <div class="label">
                                    <div class="key">{{ $t('article.code') }}</div>
                                    <div class="value">{{item.code}}</div>
                                </div>
                                <div class="label">
                                    <div class="key icon barcode"></div>
                                    <div class="value">{{ item.ean[0] }}</div>
                                </div>
                            </div>

                        <div class="description">
                            <p>
                                {{ item.description[Tr.currentLocale] ? item.description[Tr.currentLocale] : item.description["es"] }}
                            </p>
                        </div>

                        <table class="properties">
                            <tr v-for="feature in item.features" :key="feature.code">
                                <th> {{ feature[Tr.currentLocale].typeName ? feature[Tr.currentLocale].typeName : feature["es"].typeName }}</th>
                                <td>{{ feature[Tr.currentLocale].value }}</td>
                            </tr>
                        </table>

                        

                        <!-- <div class="options buttons packed vertical">

                            <a class="button" href="#">
                                <div class="icon go"></div>
                                <div class="text">
                                    Taladros percutores 220-240V
                                </div>
                            </a>

                            <a class="button" href="#">
                                <div class="icon download"></div>
                                <div class="text">
                                    Ficha técnica
                                </div>
                            </a>

                            <a class="button" href="#">
                                <div class="icon download"></div>
                                <div class="text">
                                    Instrucciones
                                </div>
                            </a>

                            <a class="button" href="#">
                                <div class="icon certificate"></div>
                                <div class="text">
                                    Declaración de conformidad
                                </div>
                            </a>

                        </div> -->

                    </div>

                    <div class="video" v-if="item.video">
                        <div class="aspectRatioWrapper">
                            <iframe type="text/html" :src="item.video" frameborder="0" allowfullscreen=""></iframe>
                        </div>
                    </div>
                </div>
                <div v-else style="display: flex; justify-content: center;">
                    <div class="half-circle-spinner">
                        <div class="circle circle-1"></div>
                        <div class="circle circle-2"></div>
                    </div>
                </div>
            </div>
        </div>
        <div class="section"><div>
            <!-- Initialize the lightbox -->
             <vue-easy-lightbox 
                v-if="item" 
                scrollDisabled
                escDisabled
                moveDisabled
                loop
                :visible="visibleRef"
                :imgs="getItemImages(item)"
                :index="indexRef"
                @hide="onHide">
            </vue-easy-lightbox>
        </div>
        </div>


        <div class="section paddingBigger light" v-if="item && item.similarProducts.length">
            <div>
                <div class="banner">
                    <div class="header">
                        <div class="subTitle">{{ $t('article.similarArticles') }}</div>
                    </div>
                </div>

                <div class="productCards">

                    <router-link 
                        :to="{ name: 'ArticleDetails', params: { subcategory: similar.url[Tr.currentLocale] ? similar.url[Tr.currentLocale]['subcategorySlug'] : similar.url['es']['subcategorySlug'], slug: similar.url[Tr.currentLocale] ? similar.url[Tr.currentLocale]['articleSlug'] : similar.url['es']['articleSlug'], id: similar.id } }" 
                        class="productCard lightBackground" 
                        v-for="similar in item.similarProducts" 
                        :key="similar.id"
                    >

                        <img :src="apiUrl+similar.image" :alt="similar.name[Tr.currentLocale] ? similar.name[Tr.currentLocale] : similar.name['es']" loading="lazy">
                        <div class="texts">
                            <div class="title">
                                {{similar.name[Tr.currentLocale] ? similar.name[Tr.currentLocale] : similar.name["es"]}}
                            </div>
                        </div>
                    </router-link>
                </div>

            </div>
        </div>

        <div class="section paddingBigger light" v-if="item && item.relatedProducts.length">
            <div>
                <div class="banner">
                    <div class="header">
                        <div class="subTitle">{{ $t('article.relatedArticles') }}</div>
                    </div>
                </div>

                <div class="productCards">
                    <router-link 
                        :to="{ name: 'ArticleDetails', params: { subcategory: related.url[Tr.currentLocale] ? related.url[Tr.currentLocale]['subcategorySlug'] : related.url['es']['subcategorySlug'], slug: related.url[Tr.currentLocale] ? related.url[Tr.currentLocale]['articleSlug'] : related.url['es']['articleSlug'], id: related.id } }" 
                        class="productCard lightBackground" 
                        v-for="related in item.relatedProducts" 
                        :key="related.id"
                    >

                        <img :src="apiUrl+related.image" :alt="related.name[Tr.currentLocale] ? related.name[Tr.currentLocale] : related.name['es']" loading="lazy">
                        <div class="texts">
                            <div class="title">
                                {{related.name[Tr.currentLocale] ? related.name[Tr.currentLocale] : related.name["es"]}}
                            </div>
                        </div>
                    </router-link>
                </div>

            </div>
        </div>

    </div>
  
</template>

<script>
import GENERIC_VARIABLES from "../../config";
import { Head } from '@vueuse/head'
import Tr from "@/translations"
import VueEasyLightbox from 'vue-easy-lightbox'
import { ref } from 'vue'




export default {
    props: ['item'],
    components: { Head, VueEasyLightbox },
    setup() {
        const apiUrl = process.env.VUE_APP_API_URL_IMAGES
        const visibleRef = ref(false)
        const indexRef = ref(0)

        const getThumbnailImage = (thumbail) => {
			return "background-image: url('"+apiUrl+thumbail+"')"
        }

        const isAvailableBrand = (brand) => {
            if(brand != GENERIC_VARIABLES.PRODUCT_BRAND ) return false
            return true
        }

        const getItemImages = (item) => {
            let newImages = []
            if(item.images.length) {
                item.images.forEach( (img, index) => {
                newImages[index] = apiUrl+img
                })
            } else {
                newImages[0] = apiUrl+item.image
            }
            return newImages
        }

        /**
		* This is a function to remove the special character | from javascript
		* More information: https://stackoverflow.com/questions/5796718/html-entity-decode
		 */
		const changeMetatagHtml = (html) => {
			let txt = document.createElement("textarea");
			txt.innerHTML = html;
			return txt.value;

		}

        const showImg = (index) => {
            indexRef.value = index
            visibleRef.value = true
        }

        const onHide = () => visibleRef.value = false

        return {
            apiUrl,
            visibleRef,
            indexRef,
            getItemImages,
            GENERIC_VARIABLES,
            Tr,
            isAvailableBrand,
            getThumbnailImage,
            changeMetatagHtml,
            showImg,
            onHide
          }
    }
    
}
</script>

<style>
.vel-modal {
    background: rgba(0,0,0,.9);
}
.vel-toolbar {
    display: none;
    visibility: hidden;
}
</style>