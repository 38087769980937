<template>
    <div id="content" class="sections">            
        <div v-if="error"> {{ checkIfError(error) }}</div>
        <Article :item="item" />
	</div>
</template>

<script>

// COMPOSITION API
import getArticle from '../../composables/getArticle'
import GENERIC_VARIABLES from "../../../config";
import Article from '@/components/Article.vue'
import {useRouter} from 'vue-router'


export default {
    props: ['subcategory', 'slug', 'id'],
    name: 'ArticleDetailsView',
	components: { Article },
    
    setup(props) {
        
        const router = useRouter()

        const checkIfError = (error) => {
            if(error.length !== 0)
                return router.push({ name: 'NotFound' })

        }
        
        // Is necessary to use the parseInt function to coerce our article id to an integer or a number.
        // Any param coming from the url is actually a string, even though our ids lloks like a number.
        const { item, error, load} = getArticle(props.subcategory,props.slug,parseInt(props.id))

        load()

                
        return {item, error, GENERIC_VARIABLES, checkIfError}

        
    }

}
</script>
